'use client'

import { useState } from 'react'
import { motion } from 'framer-motion'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface OptionProps {
  name: string
  value: string
  label: string
  text?: string
  tooltip?: {
    title: string
    text: string
  }
}

interface Props {
  name: string
  label: string
  placeholder?: string
  options: any
}

export function RadioButton({
  value,
  label,
  text,
  tooltip,
  name,
  ...rest
}: OptionProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const [tooltipOpen, setTootipOpen] = useState<boolean>(false)
  return (
    <>
      <label
        htmlFor={value}
        className='text-gray-900 relative block w-full border border-navy p-4 text-sm duration-200 hover:bg-navy/10 has-[:checked]:bg-navy has-[:checked]:text-white'
      >
        <input
          {...register(name)}
          {...rest}
          type='radio'
          className='peer/input hidden'
          id={value}
          value={value}
        />
        <span className='block text-lg'>{label}</span>
        {text ? <span className='mt-2 block'>{text}</span> : null}
        {tooltip ? (
          <div
            onClick={() => setTootipOpen(true)}
            className='absolute right-4 top-4 flex h-4 w-4 items-center justify-center rounded-full bg-navy text-white peer-checked/input:bg-white peer-checked/input:text-navy'
          >
            <svg
              width='6'
              height='8'
              viewBox='0 0 6 8'
              fill='none'
              className='fill-current'
            >
              <path d='M2.22612 5.34605C2.21263 5.28308 2.20329 5.22012 2.19603 5.15715C2.18981 5.09419 2.18669 5.02791 2.18669 4.95833C2.18669 4.70647 2.24272 4.46459 2.35893 4.23263C2.47409 4.00068 2.66708 3.78526 2.94203 3.58313L3.39855 3.24184C3.67038 3.03971 3.80942 2.79116 3.80942 2.49293C3.80942 2.3869 3.7897 2.28088 3.75339 2.17485C3.71707 2.06876 3.65793 1.97934 3.57804 1.89977C3.49815 1.82027 3.39855 1.75731 3.27612 1.71094C3.15369 1.66451 3.01466 1.63799 2.85591 1.63799C2.68368 1.63799 2.53738 1.67112 2.41807 1.73409C2.29875 1.79706 2.19603 1.87662 2.11614 1.97603C2.03729 2.07544 1.97711 2.18808 1.94079 2.31732C1.90448 2.44656 1.88477 2.57911 1.88477 2.71165C1.88477 2.83759 1.89825 2.9403 1.92108 3.01319L0.718565 2.96683C0.698851 2.89725 0.685363 2.8276 0.68225 2.75471C0.674988 2.68182 0.671875 2.61224 0.671875 2.54266C0.671875 2.2908 0.718565 2.04231 0.814019 1.80036C0.910511 1.55849 1.04954 1.34313 1.23526 1.15423C1.42098 0.968641 1.64613 0.816251 1.91797 0.703548C2.18981 0.590909 2.50107 0.53125 2.85902 0.53125C3.22631 0.53125 3.55107 0.584295 3.83328 0.693626C4.11134 0.79965 4.34686 0.942118 4.53881 1.11448C4.72764 1.28678 4.87394 1.48891 4.96939 1.71755C5.06588 1.9462 5.11257 2.17816 5.11257 2.42004C5.11257 2.79784 5.02646 3.12252 4.85423 3.39092C4.68095 3.65939 4.45269 3.89465 4.16737 4.09678L3.77725 4.37179C3.61124 4.49111 3.4857 4.62697 3.39543 4.77605C3.30621 4.9252 3.26367 5.09749 3.26367 5.29301V5.34936L2.22612 5.34605Z' />
              <path d='M2.22612 6.18445C2.07983 6.33354 2.0072 6.51251 2.0072 6.72125C2.0072 6.92999 2.07983 7.10566 2.22612 7.25144C2.37138 7.39721 2.54776 7.47017 2.75942 7.47017C2.97108 7.47017 3.15057 7.39721 3.29998 7.25144C3.44835 7.10566 3.52513 6.92999 3.52513 6.72125C3.52513 6.51251 3.44835 6.33354 3.29998 6.18445C3.15057 6.03531 2.96797 5.96242 2.75942 5.96242C2.55087 5.96242 2.37138 6.03531 2.22612 6.18445Z' />
            </svg>
          </div>
        ) : null}
      </label>
      {tooltip ? (
        tooltipOpen ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className='fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4'
          >
            <div
              onClick={() => setTootipOpen(false)}
              className='fixed inset-0 bg-black/20'
            ></div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className='relative w-full max-w-xl rounded-xl bg-white p-4 text-center'
            >
              <div
                onClick={() => setTootipOpen(false)}
                className='absolute right-2 top-2 flex h-6 w-6 items-center justify-center rounded-full bg-black/20 text-navy peer-checked/input:bg-white peer-checked/input:text-navy'
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  className='fill-current'
                >
                  <rect
                    x='4.61719'
                    y='12.9198'
                    width='12'
                    height='1.33333'
                    transform='rotate(-45 4.61719 12.9198)'
                  />
                  <rect
                    x='4.61719'
                    y='5.37622'
                    width='1.33333'
                    height='12'
                    transform='rotate(-45 4.61719 5.37622)'
                  />
                </svg>
              </div>
              <h3 className='mb-3 font-bold'>{tooltip.title}</h3>
              <p className='text-sm'>{tooltip.text}</p>
            </motion.div>
          </motion.div>
        ) : null
      ) : null}
    </>
  )
}

export default function RadioGroup({
  name,
  options,
  label,
  placeholder,
  ...rest
}: Props) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      {options.map((option: any, index: number) => {
        return (
          <div key={index} className='my-4 flex space-x-3'>
            <RadioButton
              value={option.value}
              label={option.label}
              text={option.text}
              tooltip={option.tooltip}
              name={name}
            />
          </div>
        )
      })}

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className='mt-2 text-sm text-red'>{message}</p>
        )}
      />
    </>
  )
}
