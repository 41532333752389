import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'qualifiedPurchaser',
    label: 'I am a Qualified Purchaser',
    text: 'A Qualified Purchaser is someone who has an investment portfolio of $5 million or more, excluding their primary residence. For clarity this does NOT mean that you are required to invest $5 million. This simply means that you hit the Government threshold that allows you to access all private investment funds.'
  },
  {
    value: 'accreditedInvestor',
    label: 'I am an Accredited Investor',
    text: 'An Accredited Investor is a person who has either an annual income of $200,000 individually (or $300,000 if filing jointly) OR has net worth of at least $1 million, excluding their primary residence. For clarity, this does NOT mean you are required to invest $1 million, this simply means you meet the Government threshold to access certain funds.'
  },
  {
    value: 'nonAccredited',
    label: 'I am Non-Accredited',
    text: 'I don’t currently meet the criteria above.'
  }
]

export default function QualifiedPurchaser({ step, methods }: any) {
  useScrollToTop()
  const { register, unregister, watch, setValue } = useFormContext()

  var accountType = useAppStore(state => state.accountType)
  var qualifiedPurchaser = watch('qualifiedPurchaser')

  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 5
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 3
    })
  } else {
    useAppStore.setState({
      progress: 2
    })
  }

  useEffect(() => {
    if (qualifiedPurchaser === 'qualifiedPurchaser') {
      register('accreditedInvestor')
      setValue('accreditedInvestor', 'accreditedByNetWorth')
    } else {
      unregister('accreditedInvestor')
    }
  }, [register, unregister, qualifiedPurchaser])

  var currentStep = useAppStore(state => state.currentStep)

  if (qualifiedPurchaser === 'nonAccredited') {
    useAppStore.setState({
      accreditedInvestorState: false,
      ineligible: true
    })
  } else {
    useAppStore.setState({
      accreditedInvestorState: true,
      ineligible: false
    })
  }

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        qualifiedPurchaser === 'qualifiedPurchaser'
          ? 'InvestmentAmount'
          : qualifiedPurchaser === 'accreditedInvestor'
            ? 'AccreditedInvestor'
            : 'EarlyExit'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='qualifiedPurchaser'
          label='Accredited Investor?'
          options={options}
        />
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
