'use client'

import Pagination from '../Pagination'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { Input } from '../Fields/Input'
import { Radio } from '../Fields/Radio'
import { DateField } from '../Fields/DateField'
import NumberFormatInput from '../Fields/NumberFormatInput'
import { PhoneNumberInput } from '../Fields/PhoneNumberInput'
import { useFormContext } from 'react-hook-form'
import { LocationSelect } from '../Fields/LocationSelect'
import useScrollToTop from '@/app/hooks/useScrollToTop'
import { CountrySelect } from '../Fields/CountrySelect'

interface Props {
  step: IStep
  methods: any
  countryData: any
  usStates: any
}

const options = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
]

export default function EntityInformation({
  step,
  methods,
  countryData,
  usStates
}: Props) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)
  var intInvestor = useAppStore(state => state.intInvestor)

  useAppStore.setState({
    progress: 7
  })

  var differentAddress = methods.watch(
    'doesBusinessAddressDifferFromMailingAddress'
  )

  const {
    register,
    formState: { errors }
  } = useFormContext()

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        differentAddress === 'yes'
          ? 'EntityMailingInformation'
          : 'EntityAuthorizedSignees'
    })
  }

  return (
    <>
      <input
        type='hidden'
        {...register('entityInformationIntInvestor')}
        value={intInvestor ? 'true' : 'false'}
      />
      <div className='mx-auto grid max-w-6xl grid-cols-1 gap-x-6 md:grid-cols-2'>
        <div>
          <Input
            name={'legalNameOfEntity'}
            label='Legal Name of Entity'
            placeholder='Enter Entity Name'
          />
        </div>
        <div>
          <DateField
            name='dateOfOrganization'
            label='Date of Organization'
            placeholder='mm/dd/yyyy'
          />
        </div>
        {intInvestor ? (
          <div>
            <CountrySelect
              name='countryOfFormation'
              label='Country of Formation'
              countryData={countryData}
            />
          </div>
        ) : null}
        {intInvestor ? (
          <div>
            <Input
              name='taxIdentificationNumber'
              label='Tax Identification Number'
              placeholder='XXXXXXXXXX'
            />
          </div>
        ) : (
          <div>
            <NumberFormatInput
              name='taxIdentificationNumber'
              label='Tax Identification Number'
              placeholder={'XXX-XX-XXXX'}
              format='##-#######'
            />
          </div>
        )}

        <div>
          <PhoneNumberInput
            name='entityPrimaryPhoneNumber'
            label='Primary Phone Number'
            countryData={countryData}
          />
        </div>
        <div>
          <Input
            name='businessAddressStreet'
            label='Business Address Street'
            placeholder='Enter Street Name'
          />
        </div>
        <div>
          <Input
            name='businessAddressCity'
            label='Business Address City'
            placeholder='Enter City Name'
          />
        </div>
        <div>
          <LocationSelect
            name='businessAddress'
            label='Business Address'
            countryData={countryData}
            usStates={usStates}
          />
        </div>

        <div>
          {intInvestor ? (
            <Input
              name='businessAddressZip'
              label='Business Address Zip or Postal Code'
              placeholder='12345'
            />
          ) : (
            <Input
              name='businessAddressZip'
              label='Business Address Zip'
              type='number'
              placeholder='12345'
            />
          )}
        </div>
        <div>
          <Radio
            name='doesBusinessAddressDifferFromMailingAddress'
            label='Does your place of Business differ from your mailing address?'
            options={options}
          />
        </div>
      </div>
      <div className='mt-16 flex justify-center'>
        <Pagination
          prev={'CostBasisElection'}
          next={next}
          conditions={step.conditions}
        />
      </div>
    </>
  )
}
