import { motion } from 'framer-motion'
import Image from 'next/image'
import useAppStore from '@/store/app-store'
import { twMerge } from 'tailwind-merge'
import { useFormContext } from 'react-hook-form'

interface Step {
  id: number
  title?: string
  text?: string
  component?: string
  fields?: string[]
}

interface Props {
  currentStep?: number
  next: any
  prev: any
  steps?: Step[]
  conditions?: string[]
  output?: boolean
}

export default function Pagination({ next, prev, output }: Props) {
  const {
    formState: { isSubmitting }
  } = useFormContext()
  var accreditedInvestorState = useAppStore(
    state => state.accreditedInvestorState
  )

  return (
    <div className='mt-auto w-full pt-16'>
      <div className='grid grid-cols-3 items-center'>
        <div className='flex justify-start'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <button
              type='button'
              onClick={() => {
                useAppStore.setState({ currentStep: prev })
              }}
              className='border border-navy bg-white px-4 py-3 text-sm font-semibold uppercase tracking-wide text-navy shadow-sm duration-200 hover:bg-navy/10 md:px-8'
            >
              Previous
            </button>
          </motion.div>
        </div>
        <div className='flex justify-center'>
          <Image
            src='./256-ssl-encryption.svg'
            alt='256 SSL Encryption'
            className='w-auto'
            width={96}
            height={36}
          />
        </div>
        <div className='flex justify-end'>
          {accreditedInvestorState ? (
            <button
              type='button'
              onClick={next}
              className={twMerge(
                'border border-navy bg-navy px-4 py-3 text-sm font-semibold uppercase tracking-wide text-white shadow-sm duration-200 hover:bg-navy/80 disabled:cursor-not-allowed disabled:border-gray disabled:bg-gray disabled:text-navy md:px-8',
                !output
                  ? 'bg-white text-navy shadow-sm duration-200 hover:bg-navy/10'
                  : ''
              )}
            >
              Next
            </button>
          ) : (
            <button
              type='submit'
              disabled={isSubmitting}
              className={twMerge(
                'border border-navy bg-navy px-8 py-3 text-sm font-semibold uppercase tracking-wide text-white shadow-sm duration-200 hover:bg-navy/80 disabled:cursor-not-allowed disabled:border-gray disabled:bg-gray disabled:text-navy',
                !output
                  ? 'bg-white text-navy shadow-sm duration-200 hover:bg-navy/10'
                  : ''
              )}
            >
              {isSubmitting ? 'Submitting' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
