'use client'

import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input/input-max'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
  defaultValue?: string
}

export function UsPhoneNumberInput({
  name,
  label,
  placeholder,
  type,
  defaultValue,
  ...rest
}: Props) {
  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useFormContext()

  let showValue = watch(name)

  useEffect(() => {
    if (!showValue && defaultValue !== '') {
      setValue(name, defaultValue)
    }
  }, [defaultValue])

  function onChange(value: string) {
    if (value) {
      let strippedValue = value.replace('+1', '')
      setValue(name, strippedValue)
    }
  }

  return (
    <div className='relative my-4 pt-4'>
      <>
        <PhoneInput
          country='US'
          onChange={onChange}
          className='peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0'
          id={name}
          placeholder={placeholder ? placeholder : ' '}
          value={'+1' + showValue}
        />
        <input className='hidden' {...register(name)} />
        <label
          htmlFor={name}
          className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
        >
          {label}
        </label>
      </>
    </div>
  )
}
