'use client'

import { Controller, useFormContext } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react'
import { Input } from './Input'
import { ErrorMessage } from '@hookform/error-message'

function bytesToMB(bytes: number) {
  return (bytes / (1024 * 1024)).toFixed(2) + 'MB'
}

export const FileUpload: FC<{
  name: string
  label: string
  methods: any
  multiple?: boolean
}> = ({ name, label, multiple, methods, ...rest }) => {
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors }
  } = useFormContext()

  const files = watch(name)

  var files_array: any[] = []
  if (files && files.length > 0) {
    for (var i = 0; i < files.length; i++) {
      files_array.push(files[i])
    }
  }

  const removeFiles = () => {
    setValue(name, null)
  }

  return (
    <>
      <div>
        <Controller
          render={({ field: { onChange } }) => (
            <>
              <label className='mb-4 block text-center'>{label}</label>
              <Dropzone
                name={name}
                multiple={multiple}
                onChange={e =>
                  onChange(multiple ? e.target.files : e.target.files?.[0])
                }
                methods={methods}
                {...rest}
              />
            </>
          )}
          name={name}
          control={control}
          defaultValue=''
        />
        {files_array ? (
          <ul className='mt-6 flex flex-col gap-1'>
            {files_array.map(function (file) {
              const fileSizeMB = bytesToMB(file.size)
              return (
                <li
                  key={file.name}
                  className='flex items-start border border-gray/50 bg-gray/10 p-4'
                >
                  <div className='mr-2'>
                    <svg
                      width='25'
                      height='25'
                      viewBox='0 0 25 25'
                      fill='none'
                      className='fill-current'
                    >
                      <path d='M15.8027 23.3516H9.80274C4.37273 23.3516 2.05273 21.0316 2.05273 15.6016V9.60156C2.05273 4.17156 4.37273 1.85156 9.80274 1.85156H14.8027C15.2127 1.85156 15.5527 2.19156 15.5527 2.60156C15.5527 3.01156 15.2127 3.35156 14.8027 3.35156H9.80274C5.19273 3.35156 3.55273 4.99156 3.55273 9.60156V15.6016C3.55273 20.2116 5.19273 21.8516 9.80274 21.8516H15.8027C20.4127 21.8516 22.0527 20.2116 22.0527 15.6016V10.6016C22.0527 10.1916 22.3927 9.85156 22.8027 9.85156C23.2127 9.85156 23.5527 10.1916 23.5527 10.6016V15.6016C23.5527 21.0316 21.2327 23.3516 15.8027 23.3516Z' />
                      <path d='M22.8027 11.352H18.8027C15.3827 11.352 14.0527 10.022 14.0527 6.60204V2.60204C14.0527 2.30204 14.2327 2.02204 14.5127 1.91204C14.7927 1.79204 15.1127 1.86204 15.3327 2.07204L23.3327 10.072C23.5427 10.282 23.6127 10.612 23.4927 10.892C23.3727 11.172 23.1027 11.352 22.8027 11.352ZM15.5527 4.41204V6.60204C15.5527 9.18204 16.2227 9.85204 18.8027 9.85204H20.9927L15.5527 4.41204Z' />
                      <path d='M13.8027 14.3516H7.80273C7.39273 14.3516 7.05273 14.0116 7.05273 13.6016C7.05273 13.1916 7.39273 12.8516 7.80273 12.8516H13.8027C14.2127 12.8516 14.5527 13.1916 14.5527 13.6016C14.5527 14.0116 14.2127 14.3516 13.8027 14.3516Z' />
                      <path d='M11.8027 18.3516H7.80273C7.39273 18.3516 7.05273 18.0116 7.05273 17.6016C7.05273 17.1916 7.39273 16.8516 7.80273 16.8516H11.8027C12.2127 16.8516 12.5527 17.1916 12.5527 17.6016C12.5527 18.0116 12.2127 18.3516 11.8027 18.3516Z' />
                    </svg>
                  </div>
                  <div className='mr-4'>
                    <p className=' mb-0 leading-none text-navy'>{file.name}</p>
                    <span className='inline-block text-xs leading-none text-navy/50'>
                      {fileSizeMB}
                    </span>
                  </div>
                  <button
                    type='button'
                    className='ml-auto'
                    onClick={() => removeFiles()}
                  >
                    <svg
                      width='25'
                      height='25'
                      viewBox='0 0 25 25'
                      fill='none'
                      className='fill-current'
                    >
                      <path d='M21.1423 7.07617C21.1222 7.07617 21.0923 7.07617 21.0623 7.07617C15.7723 6.54617 10.4922 6.34617 5.26225 6.87617L3.22225 7.07617C2.80225 7.11617 2.43225 6.81617 2.39225 6.39617C2.35225 5.97617 2.65225 5.61617 3.06225 5.57617L5.10225 5.37617C10.4222 4.83617 15.8123 5.04617 21.2122 5.57617C21.6222 5.61617 21.9223 5.98617 21.8822 6.39617C21.8522 6.78617 21.5222 7.07617 21.1423 7.07617Z' />
                      <path d='M8.64332 6.0657C8.60332 6.0657 8.56332 6.0657 8.51332 6.0557C8.11332 5.9857 7.83332 5.5957 7.90332 5.1957L8.12332 3.8857C8.28332 2.9257 8.50332 1.5957 10.8333 1.5957H13.4533C15.7933 1.5957 16.0133 2.9757 16.1633 3.8957L16.3833 5.1957C16.4533 5.6057 16.1733 5.9957 15.7733 6.0557C15.3633 6.1257 14.9733 5.8457 14.9133 5.4457L14.6933 4.1457C14.5533 3.2757 14.5233 3.1057 13.4633 3.1057H10.8433C9.78332 3.1057 9.76332 3.2457 9.61332 4.1357L9.38332 5.4357C9.32332 5.8057 9.00332 6.0657 8.64332 6.0657Z' />
                      <path d='M15.353 23.0953H8.93296C5.44296 23.0953 5.30297 21.1653 5.19297 19.6053L4.54297 9.53529C4.51297 9.12529 4.83296 8.76529 5.24296 8.73529C5.66296 8.71529 6.01297 9.02529 6.04297 9.43529L6.69297 19.5053C6.80297 21.0253 6.84296 21.5953 8.93296 21.5953H15.353C17.453 21.5953 17.493 21.0253 17.593 19.5053L18.243 9.43529C18.273 9.02529 18.633 8.71529 19.043 8.73529C19.453 8.76529 19.773 9.11529 19.743 9.53529L19.093 19.6053C18.983 21.1653 18.843 23.0953 15.353 23.0953Z' />
                      <path d='M13.8027 17.5957H10.4727C10.0627 17.5957 9.72266 17.2557 9.72266 16.8457C9.72266 16.4357 10.0627 16.0957 10.4727 16.0957H13.8027C14.2127 16.0957 14.5527 16.4357 14.5527 16.8457C14.5527 17.2557 14.2127 17.5957 13.8027 17.5957Z' />
                      <path d='M14.6426 13.5957H9.64258C9.23258 13.5957 8.89258 13.2557 8.89258 12.8457C8.89258 12.4357 9.23258 12.0957 9.64258 12.0957H14.6426C15.0526 12.0957 15.3926 12.4357 15.3926 12.8457C15.3926 13.2557 15.0526 13.5957 14.6426 13.5957Z' />
                    </svg>
                  </button>
                </li>
              )
            })}
          </ul>
        ) : null}
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className='mt-2 text-sm text-red'>{message}</p>
          )}
        />
      </div>
    </>
  )
}

const Dropzone: FC<{
  multiple?: boolean
  name: string
  label?: string
  methods: any
  onChange?: ChangeEventHandler<HTMLInputElement>
}> = ({ multiple, onChange, name, label, ...rest }) => {
  const { setValue, register, watch } = useFormContext()

  const files = watch(name)

  const onDrop = useCallback(
    (droppedFiles: File[]) => {
      const newFiles =
        (!!files?.length && [...files].concat(droppedFiles)) || droppedFiles

      setValue(name, newFiles, { shouldValidate: true })

      newFiles.map((file: File, i: number) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          var base64data = reader.result
          //console.log(base64data)
          setValue(name + '.' + i + '.base64', base64data)
        }
      })
    },
    [setValue, name, files]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    ...rest
  })

  return (
    <div
      {...getRootProps()}
      className='flex h-40 w-full flex-col items-center justify-center border border-dashed border-gold bg-gold/10'
    >
      <input {...getInputProps({ onChange })} />
      <div className='flex flex-col items-center justify-center'>
        <svg
          width='49'
          height='39'
          viewBox='0 0 49 39'
          fill='none'
          className='mb-4 stroke-current'
        >
          <path
            d='M12.3987 31.1008C9.59133 31.1008 6.89893 30.034 4.91382 28.1352C2.9287 26.2364 1.81348 23.6611 1.81348 20.9758C1.81348 18.2905 2.9287 15.7151 4.91382 13.8163C6.89893 11.9175 9.59133 10.8508 12.3987 10.8508C13.0617 7.89693 15.0014 5.30112 17.791 3.63439C19.1723 2.80911 20.7207 2.23676 22.3477 1.95003C23.9747 1.66331 25.6486 1.66781 27.2737 1.96328C28.8988 2.25876 30.4433 2.83942 31.8191 3.67211C33.1949 4.50481 34.3749 5.57323 35.2919 6.81637C36.2089 8.05951 36.8448 9.45304 37.1634 10.9174C37.482 12.3817 37.477 13.8882 37.1487 15.3508H39.3987C41.4873 15.3508 43.4903 16.1805 44.9672 17.6573C46.444 19.1342 47.2737 21.1372 47.2737 23.2258C47.2737 25.3144 46.444 27.3174 44.9672 28.7942C43.4903 30.2711 41.4873 31.1008 39.3987 31.1008H37.1487'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <path
            d='M16.8994 24.3516L23.6494 17.6016L30.3994 24.3516'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <path
            d='M23.6494 18.3652V37.8511'
            strokeWidth='2'
            strokeLinecap='square'
            strokeLinejoin='round'
          />
        </svg>

        {isDragActive ? (
          <p className='text-xl'>Drop files here ...</p>
        ) : (
          <p className='text-xl'>
            <span className='font-bold text-gold'>Click to Upload</span> or drag
            and drop
          </p>
        )}
        <p className='text-sm text-navy/50'> (Max. File size: 5 MB)</p>
      </div>
    </div>
  )
}
