import { z } from 'zod'
import { isValidPhoneNumber } from 'libphonenumber-js'
import validatePostalCode from '@/lib/postal-codes'

const zipRegex = new RegExp(/^\d{5}(-\d{4})?$/)

const basicInfoSchema = z
  .object({
    // component: <BasicInfo />
    firstName: z.string().min(1, 'First name is required').optional(),
    lastName: z.string().min(1, 'Last name is required').optional(),
    emailAddress: z
      .string()
      .min(1, 'Email address is required')
      .email('Invalid email address')
      .optional(),
    riaRep: z.string({ invalid_type_error: 'Please select' }).optional(),
    usTaxable: z.string().min(1, 'Please select').optional(),
    phoneNumber: z.string().min(1, 'Required field').optional(),
    phoneNumberCountryCode: z.string().min(1, 'Required field').optional()
  })
  .superRefine(
    ({ usTaxable, phoneNumber, phoneNumberCountryCode }, refinementContext) => {
      if (
        usTaxable === 'international' &&
        !isValidPhoneNumber(phoneNumber ?? '')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid phone number',
          path: ['phoneNumber']
        })
      }
      if (usTaxable === 'international' && !phoneNumberCountryCode) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select a country',
          path: ['phoneNumber']
        })
      }
      if (usTaxable === 'us' && !isValidPhoneNumber(phoneNumber ?? '', 'US')) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid US phone number',
          path: ['phoneNumber']
        })
      }
    }
  )

const accountTypeSchema = z.object({
  // component: <AccountType />
  accountType: z
    .string({ invalid_type_error: 'Please select an account' })
    .optional()
})

const jointAccountSchema = z.object({
  // component: <JointAccount />
  jointAccountType: z
    .string({
      invalid_type_error: 'Please select an account'
    })
    .optional()
})

const accreditedInvestorSchema = z.object({
  // component: <AccreditedInvestor />
  accreditedInvestor: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const entityAccountSchema = z.object({
  // component: <EntityAccount />
  entityAccount: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const investmentAmountSchema = z.object({
  // component: <InvestmentAmount />
  investmentAmount: z
    .number({
      required_error: 'Amount is required',
      invalid_type_error: 'Amount must be a number'
    })
    .gte(25000, 'Minimum investment $25,000')
    //.lte(500000, 'Maximum investment $500,000')
    .multipleOf(1000, 'Amount must be in increments of $1000')
    .optional()
})

const costBasisElectionSchema = z.object({
  // component: <CostBasisElection />
  costBasisElection: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const distributionSchema = z.object({
  // component: <Distribution />
  distribution: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const financialInformationSchema = z
  .object({
    // component: <FinancialInformation />
    financialInformationIntInvestor: z
      .string()
      .min(1, 'Required field')
      .optional(),
    bankName: z.string().min(1, 'Required field').optional(),
    bankStreet: z.string().min(1, 'Required field').optional(),
    bankCity: z.string().min(1, 'Required field').optional(),
    bankCountry: z.string().min(1, 'Required field').optional(),
    bankState: z.string().min(1, 'Required field').optional(),
    bankZip: z.string().optional(),
    bankAccountType: z.string().min(1, 'Required field').optional(),
    abaNumber: z
      .string()
      .min(9, 'Must be 9 digits')
      .max(9, 'Must be 9 digits')
      .optional(),
    bankIdentifierCode: z.string().min(1, 'Required field').optional(),
    bankAccountNumber: z.string().min(1, 'Required field').optional(),
    nameOnBankAccount: z.string().min(1, 'Required field').optional(),
    forFurtherCreditName: z.string().optional(),
    forFurtherCreditNumber: z.string().optional(),
    referenceNumber: z.string().optional()
  })
  .superRefine(
    (
      { financialInformationIntInvestor, bankZip, bankCountry },
      refinementContext
    ) => {
      if (financialInformationIntInvestor === 'false') {
        if (bankZip?.length === 0) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
            path: ['bankZip']
          })
        } else if (!zipRegex.test(bankZip ?? '')) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Zip',
            path: ['bankZip']
          })
        }
      } else if (
        bankCountry &&
        !validatePostalCode(bankCountry)(bankZip ?? '')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Zip',
          path: ['bankZip']
        })
      }
    }
  )

const personalInformationSchema = z
  .object({
    // component: <PersonalInformation />
    personalInformationIntInvestor: z.string().optional(),
    mailingAddressNumberAndStreet: z
      .string()
      .min(1, 'Required field')
      .optional(),
    mailingAddressCity: z.string().min(1, 'Required field').optional(),
    mailingAddressCountry: z.string().min(1, 'Required field').optional(),
    mailingAddressState: z.string().min(1, 'Required field').optional(),
    mailingAddressZip: z.string().optional(),
    socialSecurity: z.string().min(1, 'Required field').optional(),
    birthDate: z.date({ invalid_type_error: 'Required field' }).optional(),
    countryOfNationality: z.string().min(1, 'Required field').optional()
  })
  .superRefine(
    ({ personalInformationIntInvestor, socialSecurity }, refinementContext) => {
      if (
        personalInformationIntInvestor !== 'true' &&
        socialSecurity &&
        socialSecurity.length !== 9
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Social Security must be 9 digits',
          path: ['socialSecurity']
        })
      }
    }
  )
  .superRefine(
    (
      {
        personalInformationIntInvestor,
        mailingAddressZip,
        mailingAddressCountry
      },
      refinementContext
    ) => {
      if (personalInformationIntInvestor === 'false') {
        if (mailingAddressZip?.length === 0) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
            path: ['mailingAddressZip']
          })
        } else if (!zipRegex.test(mailingAddressZip ?? '')) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Zip',
            path: ['mailingAddressZip']
          })
        }
      } else if (
        mailingAddressZip &&
        mailingAddressCountry &&
        !validatePostalCode(mailingAddressCountry)(mailingAddressZip ?? '')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Zip',
          path: ['mailingAddressZip']
        })
      }
    }
  )

const trustAccountTypeSchema = z.object({
  // component: <TrustAccount />
  trustAccountType: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const trustIrrevocableAccreditedInvestorSchema = z.object({
  // component: <TrustIrrevocableAccreditedInvestor />
  accreditedInvestor: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const trustRevocableAccreditedInvestorSchema = z.object({
  // component: <TrustRevocableAccreditedInvestor />
  accreditedInvestor: z
    .string({
      invalid_type_error: 'Please select an option'
    })
    .optional()
})

const coOwnerInformationSchema = z
  .object({
    // component: <CoOwnerInformation />
    coOwnerInformationIntInvestor: z.string().optional(),
    coOwnerFirstName: z.string().min(1, 'Required field').optional(),
    coOwnerLastName: z.string().min(1, 'Required field').optional(),
    coOwnerEmailAddress: z
      .string()
      .min(1, 'Email address is required')
      .email('Invalid email address')
      .optional(),
    coOwnerSocialSecurity: z.coerce
      .string()
      .min(1, 'Required field')
      .optional(),
    coOwnerBirthDate: z
      .date({ invalid_type_error: 'Required field' })
      .optional(),
    coOwnerPhoneNumber: z.string().min(1, 'Required field').optional(),
    coOwnerPhoneNumberCountryCode: z
      .string()
      .min(1, 'Required field')
      .optional(),
    coOwnerCountryOfNationality: z.string().min(1, 'Required field').optional()
  })
  .superRefine(
    (
      { coOwnerInformationIntInvestor, coOwnerSocialSecurity },
      refinementContext
    ) => {
      if (
        coOwnerInformationIntInvestor !== 'true' &&
        coOwnerSocialSecurity &&
        coOwnerSocialSecurity.length !== 9
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Social Security must be 9 digits',
          path: ['coOwnerSocialSecurity']
        })
      }
    }
  )
  .superRefine(
    (
      { coOwnerInformationIntInvestor, coOwnerPhoneNumber },
      refinementContext
    ) => {
      if (
        coOwnerInformationIntInvestor === 'true' &&
        !isValidPhoneNumber(coOwnerPhoneNumber ?? '')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid phone number',
          path: ['coOwnerPhoneNumber']
        })
      }
      if (
        coOwnerInformationIntInvestor === 'false' &&
        !isValidPhoneNumber(coOwnerPhoneNumber ?? '', 'US')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid US phone number',
          path: ['coOwnerPhoneNumber']
        })
      }
    }
  )

const entityInformationSchema = z
  .object({
    // component: <EntityInformation />
    entityInformationIntInvestor: z.string().optional(),
    legalNameOfEntity: z.string().min(1, 'Required field').optional(),
    dateOfOrganization: z
      .date({ invalid_type_error: 'Required field' })
      .optional(),
    countryOfFormation: z.string().min(1, 'Required field').optional(),
    taxIdentificationNumber: z.string().min(1, 'Required field').optional(),
    entityPrimaryPhoneNumber: z.string().min(1, 'Required field').optional(),
    entityPrimaryPhoneNumberCountryCode: z
      .string()
      .min(1, 'Required field')
      .optional(),
    businessAddressStreet: z.string().min(1, 'Required field').optional(),
    businessAddressCity: z.string().min(1, 'Required field').optional(),
    businessAddressCountry: z.string().min(1, 'Required field').optional(),
    businessAddressState: z.string().min(1, 'Required field').optional(),
    businessAddressZip: z.string().optional(),
    doesBusinessAddressDifferFromMailingAddress: z
      .string({ invalid_type_error: 'Required field' })
      .min(1, 'Required field')
      .optional()
  })
  .superRefine(
    (
      {
        entityInformationIntInvestor,
        businessAddressZip,
        businessAddressCountry
      },
      refinementContext
    ) => {
      if (entityInformationIntInvestor === 'false') {
        if (businessAddressZip?.length === 0) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
            path: ['businessAddressZip']
          })
        } else if (!zipRegex.test(businessAddressZip ?? '')) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Zip',
            path: ['businessAddressZip']
          })
        }
      } else if (
        businessAddressZip &&
        businessAddressCountry &&
        !validatePostalCode(businessAddressCountry)(businessAddressZip ?? '')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Zip',
          path: ['businessAddressZip']
        })
      }
    }
  )
  .superRefine(
    (
      { entityInformationIntInvestor, taxIdentificationNumber },
      refinementContext
    ) => {
      if (
        entityInformationIntInvestor !== 'true' &&
        taxIdentificationNumber &&
        taxIdentificationNumber.length !== 9
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Tax Identification Number must be 9 digits',
          path: ['taxIdentificationNumber']
        })
      }
    }
  )
  .superRefine(
    (
      { entityInformationIntInvestor, entityPrimaryPhoneNumber },
      refinementContext
    ) => {
      if (
        entityInformationIntInvestor === 'true' &&
        !isValidPhoneNumber(entityPrimaryPhoneNumber ?? '')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid phone number',
          path: ['entityPrimaryPhoneNumber']
        })
      }
      if (
        entityInformationIntInvestor === 'false' &&
        !isValidPhoneNumber(entityPrimaryPhoneNumber ?? '', 'US')
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid US phone number',
          path: ['entityPrimaryPhoneNumber']
        })
      }
    }
  )

const entityMailingInformationSchema = z
  .object({
    // component: <EntityMailingInformation />
    entityMailingInformationIntInvestor: z.string().optional(),
    businessMailingAddressStreet: z
      .string()
      .min(1, 'Required field')
      .optional(),
    businessMailingAddressCountry: z
      .string()
      .min(1, 'Required field')
      .optional(),
    businessMailingAddressCity: z.string().min(1, 'Required field').optional(),
    businessMailingAddressState: z.string().min(1, 'Required field').optional(),
    businessMailingAddressZip: z.string().optional()
  })
  .superRefine(
    (
      {
        entityMailingInformationIntInvestor,
        businessMailingAddressZip,
        businessMailingAddressCountry
      },
      refinementContext
    ) => {
      if (entityMailingInformationIntInvestor === 'false') {
        if (businessMailingAddressZip?.length === 0) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
            path: ['businessMailingAddressZip']
          })
        } else if (!zipRegex.test(businessMailingAddressZip ?? '')) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Zip',
            path: ['businessMailingAddressZip']
          })
        }
      } else if (
        businessMailingAddressZip &&
        businessMailingAddressCountry &&
        !validatePostalCode(businessMailingAddressCountry)(
          businessMailingAddressZip ?? ''
        )
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Zip',
          path: ['businessMailingAddressZip']
        })
      }
    }
  )

const entityAuthorizedSigneesSchema = z
  .object({
    // component: <EntityAuthorizedSignees />
    entityAuthorizedSigneesIntInvestor: z.string().optional(),
    entityAuthorizedSignees: z
      .array(
        z.object({
          firstName: z.string().min(1, 'First name is required').optional(),
          lastName: z.string().min(1, 'First name is required').optional(),
          email: z
            .string()
            .min(1, 'Email address is required')
            .email('Invalid email address')
            .optional(),
          dateOfBirth: z
            .date({ invalid_type_error: 'Required field' })
            .optional(),
          socialSecurity: z.string().optional()
        })
      )
      .min(1, 'Please include at least one Authorized Signee')
      .max(2, 'Include no more then two Authorized Signees')
      .optional()
  })
  .superRefine(
    (
      { entityAuthorizedSigneesIntInvestor, entityAuthorizedSignees },
      refinementContext
    ) => {
      if (
        entityAuthorizedSigneesIntInvestor !== 'true' &&
        entityAuthorizedSignees
      ) {
        entityAuthorizedSignees.map((signee, index) => {
          if (signee.socialSecurity?.length !== 9) {
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Social Security must be 9 digits',
              path: ['entityAuthorizedSignees', index, 'socialSecurity']
            })
          }
        })
      }
    }
  )

const entityOwnerInformantionSchema = z
  .object({
    // component: <EntityOwnerInformantion />
    entityOwnerInformantionIntInvestor: z.string().optional(),
    entityOwnerInformantion: z
      .array(
        z.object({
          firstName: z.string().min(1, 'First name is required').optional(),
          lastName: z.string().min(1, 'First name is required').optional(),
          country: z.string().min(1, 'Required field').optional(),
          ownershipPercentage: z.coerce
            .number()
            .min(25, 'Minimum ownership percentage is 25%')
            .max(100, 'Maximum ownership percentage is 100%')
            .optional(),
          streetAddress: z.string().min(1, 'Required field').optional(),
          city: z.string().min(1, 'Required field').optional(),
          countryOfNationality: z.string().min(1, 'Required field').optional(),
          state: z.string().min(1, 'Required field').optional(),
          zip: z.string().optional(),
          dateOfBirth: z
            .date({ invalid_type_error: 'Required field' })
            .optional(),
          socialSecurity: z.string().optional()
        })
      )
      .min(1, 'Please include at least one Owner')
      .max(4, 'Include no more then four Owners')
      .optional()
  })
  .superRefine(
    (
      { entityOwnerInformantionIntInvestor, entityOwnerInformantion },
      refinementContext
    ) => {
      if (
        entityOwnerInformantionIntInvestor !== 'true' &&
        entityOwnerInformantion
      ) {
        entityOwnerInformantion.map((signee, index) => {
          if (signee.socialSecurity?.length !== 9) {
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Tax Identification Number must be 9 digits',
              path: ['entityOwnerInformantion', index, 'socialSecurity']
            })
          }
        })
      }
    }
  )
  .superRefine(
    (
      { entityOwnerInformantionIntInvestor, entityOwnerInformantion },
      refinementContext
    ) => {
      if (
        entityOwnerInformantionIntInvestor !== 'true' &&
        entityOwnerInformantion
      ) {
        entityOwnerInformantion.map((signee, index) => {
          if (signee.zip?.length === 0) {
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Required field',
              path: ['entityOwnerInformantion', index, 'zip']
            })
          } else if (!zipRegex.test(signee.zip ?? '')) {
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid Zip',
              path: ['entityOwnerInformantion', index, 'zip']
            })
          }
        })
      } else if (entityOwnerInformantion) {
        entityOwnerInformantion.map((signee, index) => {
          if (
            signee.country &&
            !validatePostalCode(signee.country)(signee.zip ?? '')
          ) {
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid Zip',
              path: ['entityOwnerInformantion', index, 'zip']
            })
          }
        })
      }
    }
  )

const documentUploadSchema = z.object({
  // component: <DocumentUpload />
  documents: z
    .array(
      z.object({
        path: z.string().min(1).optional(),
        base64: z.string().min(1).optional()
      }),
      { invalid_type_error: 'Please include at least one document' }
    )
    .min(1, 'Please include at least one document')
    .optional()
})

const documentUploadTrustSchema = z.object({
  // component: <DocumentUploadTrust />
  trustFormationDocument: z
    .array(
      z.object({
        path: z.string().min(1).optional(),
        base64: z.string().min(1).optional()
      }),
      { invalid_type_error: 'Please include at least one document' }
    )
    .min(1, 'Please include at least one document')
    .optional(),
  OwnerDocuments: z
    .array(
      z.object({
        path: z.string().min(1).optional(),
        base64: z.string().min(1).optional()
      }),
      { invalid_type_error: 'Please include at least one document' }
    )
    .min(1, 'Please include at least one document')
    .optional()
})

const documentUploadJointSchema = z.object({
  // component: <DocumentUploadJoint />
  ownerOneDocuments: z
    .array(
      z.object({
        path: z.string().min(1).optional(),
        base64: z.string().min(1).optional()
      }),
      { invalid_type_error: 'Please include at least one document' }
    )
    .min(1, 'Please include at least one document')
    .optional(),
  ownerTwoDocuments: z
    .array(
      z.object({
        path: z.string().min(1).optional(),
        base64: z.string().min(1).optional()
      }),
      { invalid_type_error: 'Please include at least one document' }
    )
    .min(1, 'Please include at least one document')
    .optional()
})

const earlyExitSchema = z.object({
  // component: <EarlyExit />
  iraType: z.string().optional(),
  custodianName: z.string().optional()
})

const combinedSchema = basicInfoSchema
  .and(accountTypeSchema)
  .and(jointAccountSchema)
  .and(accreditedInvestorSchema)
  .and(entityAccountSchema)
  .and(investmentAmountSchema)
  .and(costBasisElectionSchema)
  .and(distributionSchema)
  .and(financialInformationSchema)
  .and(personalInformationSchema)
  .and(trustAccountTypeSchema)
  .and(trustIrrevocableAccreditedInvestorSchema)
  .and(trustRevocableAccreditedInvestorSchema)
  .and(coOwnerInformationSchema)
  .and(entityInformationSchema)
  .and(entityMailingInformationSchema)
  .and(entityAuthorizedSigneesSchema)
  .and(entityOwnerInformantionSchema)
  .and(documentUploadSchema)
  .and(documentUploadTrustSchema)
  .and(documentUploadJointSchema)
  .and(earlyExitSchema)

export const FormDataSchema = combinedSchema
