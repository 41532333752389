'use client'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import useAppStore from '@/store/app-store'
import { IntPhoneNumberInput } from './IntPhoneNumberInput'
import { UsPhoneNumberInput } from './UsPhoneNumberInput'

interface Props {
  name: string
  label: string
  value?: string
  countryData: any
}

export function PhoneNumberInput({
  name,
  label,
  value,
  countryData,
  ...rest
}: Props) {
  const {
    formState: { errors }
  } = useFormContext()

  var intInvestor = useAppStore(state => state.intInvestor)

  return (
    <div className='relative my-4'>
      {intInvestor ? (
        <IntPhoneNumberInput
          name={name}
          label={label}
          placeholder='+000000000000'
          countryData={countryData}
          value={value}
        />
      ) : (
        <UsPhoneNumberInput
          name={name}
          label={label}
          placeholder='(000) 000-0000'
          defaultValue={value}
        />
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className='mt-2 text-sm text-red'>{message}</p>
        )}
      />
    </div>
  )
}
