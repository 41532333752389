import useAppStore from '@/store/app-store'
import Pagination from '../Pagination'
import { FileUpload } from '../Fields/FileUpload'
import useScrollToTop from '@/app/hooks/useScrollToTop'

export default function DocumentUploadJoint({ step, methods }: any) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)

  useAppStore.setState({
    progress: 10
  })

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'Review'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-3xl space-y-16'>
        <>
          <FileUpload
            methods={methods}
            name='ownerOneDocuments'
            label='Joint Owner: please upload a PDF copy of a valid Drivers License/Photo ID.'
          />

          <FileUpload
            methods={methods}
            name='ownerTwoDocuments'
            label='Joint Owner: please upload a PDF copy of a valid Drivers License/Photo ID.'
          />
        </>
      </div>
      <Pagination
        prev={'CostBasisElection'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
