import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'partnership',
    label: 'Partnership',
    text: 'Typically an LP, LLP or GP, this is a formal arrangement by two or more parties to manage and operate a business'
  },
  {
    value: 'corporation',
    label: 'Corporation',
    text: 'An S-Corp, C-Corp, LLC, or Sole Proprietorship'
  },
  {
    value: 'pensionPlan',
    label: 'Pension Plan',
    text: 'Employer-funded retirement income arrangement'
  },
  {
    value: 'endowment',
    label: 'Endowment',
    text: 'Invested sum generating income for a purpose'
  },
  {
    value: 'foundation',
    label: 'Foundation',
    text: 'Non-profit organization with a specific mission, funded for a cause'
  }
]

export default function EntityAccount({ step, methods }: any) {
  useScrollToTop()
  useAppStore.setState({
    progress: 3,
    entityState: true
  })

  var currentStep = useAppStore(state => state.currentStep)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'EarlyExit'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='entityAccount'
          label='Entity Account'
          options={options}
        />
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
