import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
  value?: string
  format: string
}

export default function NumberFormatInput({
  name,
  label,
  placeholder,
  type,
  value,
  format,
  ...rest
}: Props) {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext()

  useEffect(() => {
    register(name)
  })

  return (
    <>
      <div className='relative my-4 pt-4'>
        <Controller
          render={({ field: { onChange, value } }) => (
            <>
              <PatternFormat
                format={format}
                mask={'_'}
                valueIsNumericString={true}
                onValueChange={v => {
                  onChange(v.value)
                }}
                displayType='input'
                value={value}
                placeholder={placeholder}
                className='peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0'
              />
              <label
                htmlFor={name}
                className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
              >
                {label}
              </label>
            </>
          )}
          name={name}
          control={control}
          defaultValue=''
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className='mt-2 text-sm text-red'>{message}</p>
          )}
        />
      </div>
    </>
  )
}
