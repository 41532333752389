'use client'

import Pagination from '../Pagination'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { Input } from '../Fields/Input'
import NumberFormatInput from '../Fields/NumberFormatInput'
import { DateField } from '../Fields/DateField'
import { useFormContext } from 'react-hook-form'
import { LocationSelect } from '../Fields/LocationSelect'
import useScrollToTop from '@/app/hooks/useScrollToTop'
import { Country } from 'react-phone-number-input/core'
import { CountrySelect } from '../Fields/CountrySelect'

interface Props {
  step: IStep
  methods: any
  countryData: any
  usStates: any
}

export default function PersonalInformation({
  step,
  methods,
  countryData,
  usStates
}: Props) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)
  var intInvestor = useAppStore(state => state.intInvestor)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 5
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 8
    })
  } else {
    useAppStore.setState({
      progress: 7
    })
  }

  const {
    register,
    formState: { errors }
  } = useFormContext()

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        accountType === 'joint' ? 'CoOwnerInformation' : 'DocumentUpload'
    })
  }

  return (
    <>
      <div className='mx-auto grid max-w-6xl grid-cols-1 gap-x-6 md:grid-cols-2'>
        <input
          type='hidden'
          {...register('personalInformationIntInvestor')}
          value={intInvestor ? 'true' : 'false'}
        />
        <div>
          <Input
            name='mailingAddressNumberAndStreet'
            label='Mailing Address Number and Street'
            placeholder='Enter Number and Street'
          />
        </div>
        <div>
          <Input
            name='mailingAddressCity'
            label='Mailing Address City'
            placeholder='Enter City'
          />
        </div>
        <div>
          <LocationSelect
            name='mailingAddress'
            label='Mailing Address'
            countryData={countryData}
            usStates={usStates}
          />
        </div>
        <div>
          {intInvestor ? (
            <Input
              name='mailingAddressZip'
              label='Mailing Address Zip or Postal Code'
              placeholder='12345'
            />
          ) : (
            <Input
              name='mailingAddressZip'
              label='Mailing Address Zip'
              type='number'
              placeholder='12345'
            />
          )}
        </div>
        {intInvestor ? (
          <div>
            <Input
              name='socialSecurity'
              label='Government ID Number'
              placeholder='XXXXXXXXXX'
            />
          </div>
        ) : (
          <div>
            <NumberFormatInput
              name='socialSecurity'
              label='Social Security'
              placeholder={'XXX-XX-XXXX'}
              format='###-##-####'
            />
          </div>
        )}

        <div>
          <DateField
            name='birthDate'
            label='Birth Date'
            placeholder='mm/dd/yy'
          />
        </div>
        {intInvestor ? (
          <div>
            <CountrySelect
              name='countryOfNationality'
              label='Country of Nationality'
              countryData={countryData}
            />
          </div>
        ) : null}
      </div>
      <div>
        <Pagination
          prev={'Distribution'}
          next={next}
          conditions={step.conditions}
        />
      </div>
    </>
  )
}
