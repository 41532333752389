'use client'

import Pagination from '../Pagination'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { Input } from '../Fields/Input'
import NumberFormatInput from '../Fields/NumberFormatInput'
import { DateField } from '../Fields/DateField'
import { ErrorMessage } from '@hookform/error-message'
import useScrollToTop from '@/app/hooks/useScrollToTop'

interface Props {
  step: IStep
  methods: any
}

export default function EntityAuthorizedSignees({ step, methods }: Props) {
  useScrollToTop()
  useAppStore.setState({
    progress: 9
  })

  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)
  var intInvestor = useAppStore(state => state.intInvestor)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'EntityOwnerInformantion'
    })
  }
  const {
    register,
    control,
    reset,
    formState: { errors }
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'entityAuthorizedSignees'
  })

  return (
    <>
      <input
        type='hidden'
        {...register('entityAuthorizedSigneesIntInvestor')}
        value={intInvestor ? 'true' : 'false'}
      />
      <div className='flex justify-center'>
        <div className='w-full max-w-lg'>
          <h3 className='font-serif text-2xl'>Entity Authorized Signees</h3>
          <ul className='w-full'>
            {fields.map((item, index) => {
              return (
                <li key={item.id}>
                  <Input
                    name={'entityAuthorizedSignees.' + index + '.firstName'}
                    label='First Name of Authorized Signor'
                    placeholder='Enter First Name Here'
                  />
                  <Input
                    name={'entityAuthorizedSignees.' + index + '.lastName'}
                    label='Last Name of Authorized Signor'
                    placeholder='Enter Last Name Here'
                  />
                  <Input
                    name={'entityAuthorizedSignees.' + index + '.email'}
                    label='Authorized Signor Email'
                    placeholder='Enter Email Here'
                  />

                  <DateField
                    name={'entityAuthorizedSignees.' + index + '.dateOfBirth'}
                    label='Date of Birth'
                    placeholder='Enter Email Here'
                  />

                  {intInvestor ? (
                    <div>
                      <Input
                        name={
                          'entityAuthorizedSignees.' + index + '.socialSecurity'
                        }
                        label='Government ID Number'
                        placeholder='XXXXXXXXXX'
                      />
                    </div>
                  ) : (
                    <div>
                      <NumberFormatInput
                        name={
                          'entityAuthorizedSignees.' + index + '.socialSecurity'
                        }
                        label='Social Security'
                        placeholder={'XXX-XX-XXXX'}
                        format='###-##-####'
                      />
                    </div>
                  )}

                  <button
                    type='button'
                    onClick={() => remove(index)}
                    className='text-navy/50 underline'
                  >
                    Remove
                  </button>
                </li>
              )
            })}
          </ul>

          {fields.length < 2 ? (
            <section className='mt-16 flex justify-center'>
              <button
                type='button'
                onClick={() => {
                  append({})
                }}
                className='flex items-center gap-2 bg-navy px-6 py-3 text-sm font-bold uppercase text-white'
              >
                <svg
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                  className='stroke-current'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.97188 7.93281H13.2219V6.13281H7.97188V0.882812H6.17188V6.13281H0.921875V7.93281H6.17188V13.1828H7.97188V7.93281ZM7.82188 1.03281V6.28281H13.0719V7.78281H13.0703V6.2832H7.82031V1.0332H6.32188V1.03281H7.82188ZM6.32031 6.28281H1.07188V6.2832H6.32031V6.28281ZM7.82031 13.0328H7.82188V7.7832H7.82031V13.0328Z'
                    fill='white'
                  />
                </svg>
                <span>Add signee</span>
              </button>
            </section>
          ) : null}
          <ErrorMessage
            errors={errors}
            name={'entityAuthorizedSignees'}
            render={({ message }) => (
              <p className='mt-2 text-sm text-red'>{message}</p>
            )}
          />
        </div>
      </div>
      <div className='mt-16 flex justify-center'>
        <Pagination
          prev={'EntityInformation'}
          next={next}
          conditions={step.conditions}
        />
      </div>
    </>
  )
}
