import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'individual',
    label: 'Individual Account',
    text: 'Ownership by a single person using their legal name.'
  },
  {
    value: 'individualRetirement',
    label: 'Individual Retirement Account',
    text: 'Investing through an existing IRA or ROTH IRA.'
  },
  {
    value: 'joint',
    label: 'Joint Account',
    text: 'An investment account shared by two or more individuals, enabling collaborative ownership and management of the investment portfolio.'
  },
  {
    value: 'trust',
    label: 'Trust Account',
    text: 'An account held in trust for the benefit of one or more beneficiaries, typically managed by a trustee in accordance with specified terms and conditions.'
  },
  {
    value: 'entity',
    label: 'Entity Account',
    text: 'Corporation, Partnership, Pension Plan, Endowment, Foundation'
  }
]

export default function AccountType({ step, methods }: any) {
  useScrollToTop()

  var accountTypeValue = methods.watch('accountType')
  useAppStore.setState({
    progress: 1,
    iraState: accountTypeValue === 'individualRetirement' ? true : false
  })

  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        accountTypeValue === 'individual'
          ? 'QualifiedPurchaser'
          : accountTypeValue === 'individualRetirement'
            ? 'EarlyExit'
            : accountTypeValue === 'joint'
              ? 'JointAccount'
              : accountTypeValue === 'trust'
                ? 'TrustAccount'
                : accountTypeValue === 'entity'
                  ? 'EntityAccount'
                  : 'BasicInfo',
      accountType: accountTypeValue
    })
  }

  // set the account type to global state for later use
  useAppStore.setState({
    accountType: accountTypeValue
  })

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup name='accountType' label='Account Type' options={options} />
      </div>
      <Pagination prev={'BasicInfo'} next={next} conditions={step.conditions} />
    </>
  )
}
