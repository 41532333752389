import { Input } from '../Fields/Input'
import { RadioButtons } from '../Fields/RadioButtons'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { Select } from '../Fields/Select'
import { useSearchParams } from 'next/navigation'

import { PhoneNumberInput } from '../Fields/PhoneNumberInput'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const riaOptions = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
]

const taxableOptions = [
  {
    value: 'us',
    label: 'US'
  },
  {
    value: 'international',
    label: 'International'
  }
]

interface Props {
  step: IStep
  methods: any
  countryData: any
  usStates: any
}

export default function BasicInfo({
  step,
  methods,
  countryData,
  usStates
}: Props) {
  const searchParams = useSearchParams()
  const auth0UserId = searchParams.get('user_id')
  const firstNameSearchParam = searchParams.get('first_name')
  const lastNameSearchParam = searchParams.get('last_name')
  const emailSearchParam = searchParams.get('email')
  const phoneSearchParam = searchParams.get('phone')

  useScrollToTop()
  var riaRep = methods.watch('riaRep')
  var usTaxable = methods.watch('usTaxable')

  var currentStep = useAppStore(state => state.currentStep)

  const firstNameFormValue = methods.watch('firstName')
  const lastNameFormValue = methods.watch('lastName')
  const emailNameFormValue = methods.watch('emailAddress')
  const phoneNameFormValue = methods.watch('phoneNumber')

  const saveFieldsToAuth0 = async () => {
    debugger;
    if (auth0UserId) {
      const user_metadata: {
        first_name?: string
        last_name?: string
        email_address?: string
        phone_number?: string
      } = {}

      if (!firstNameSearchParam) {
        user_metadata.first_name = firstNameFormValue
      }

      if (!lastNameSearchParam) {
        user_metadata.last_name = lastNameFormValue
      }

      if (!emailSearchParam) {
        user_metadata.email_address = emailNameFormValue
      }

      if (!phoneSearchParam) {
        user_metadata.phone_number = phoneNameFormValue
      }

      await fetch('api/updateAuth0User', {
        method: 'POST',
        body: JSON.stringify({
          user_id: auth0UserId,
          data: {
            user_metadata
          }
        })
      })
    }
  }

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    await saveFieldsToAuth0()

    useAppStore.setState({
      progress: 0,
      currentStep: riaRep === 'no' ? 'AccountType' : 'EarlyExit',
      intInvestor: usTaxable === 'international' ? true : false
    })
  }

  return (
    <>
      <div className='flex justify-center'>
        <div className='w-full max-w-lg'>
          <Input
            name='firstName'
            label='First Name*'
            placeholder='Enter Your First Name'
            value={firstNameSearchParam ? firstNameSearchParam : ''}
            defaultValue={firstNameSearchParam ? firstNameSearchParam : ''}
          />
          <Input
            name='lastName'
            label='Last Name*'
            placeholder='Enter Your Last Name'
            value={lastNameSearchParam ? lastNameSearchParam : ''}
            defaultValue={lastNameSearchParam ? lastNameSearchParam : ''}
          />
          <Input
            name='emailAddress'
            label='Email Address*'
            type='email'
            placeholder='Enter Email Address Here'
            value={emailSearchParam ? emailSearchParam : ''}
            defaultValue={emailSearchParam ? emailSearchParam : ''}
          />

          <Select
            name='usTaxable'
            label='Are you a U.S. Person or investing via a U.S. Entity?*'
            placeholder='Select'
            onChange={e => {
              useAppStore.setState({
                intInvestor: e.target.value === 'international' ? true : false
              })
            }}
          >
            <option value='' disabled>
              Select Option
            </option>
            {taxableOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <PhoneNumberInput
            name='phoneNumber'
            label='Phone Number*'
            countryData={countryData}
            value={phoneSearchParam ? phoneSearchParam : ''}
          />

          <RadioButtons
            name='riaRep'
            label='Are you a representative of a Registered Investment Advisor ("RIA") looking to fill this form out on behalf of your client(s)? *'
            options={riaOptions}
          />
        </div>
      </div>
      <div className='mt-16 flex justify-center'>
        <button
          type='button'
          onClick={next}
          className={
            'border border-navy bg-navy px-8 py-3 text-sm font-semibold uppercase tracking-wide text-white shadow-sm duration-200 hover:bg-navy/80'
          }
        >
          Next
        </button>
      </div>
    </>
  )
}
