import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface StoreProps {
  currentStep: string
  intInvestor: boolean
  accountType: string
  iraState: boolean
  entityState: boolean
  progress: number
  totalSteps: number
  ineligible: boolean
  accreditedInvestorState: boolean
}

const initialState: StoreProps = {
  currentStep: 'BasicInfo',
  intInvestor: false,
  accountType: 'individual',
  iraState: false,
  entityState: false,
  progress: 0,
  totalSteps: 11,
  ineligible: false,
  accreditedInvestorState: true
}

const useAppStore = create(
  devtools<StoreProps>(() => initialState, { name: 'app-store' })
)
export default useAppStore
