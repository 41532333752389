'use client'

import { SetStateAction, useState } from 'react'
import { MoneyInput } from '../Fields/MoneyInput'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { twMerge } from 'tailwind-merge'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
]

interface Props {
  step: IStep
  methods: any
}

export default function InvestmentAmount({ step, methods }: Props) {
  useScrollToTop()
  var accountType = useAppStore(state => state.accountType)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 4
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 4
    })
  } else {
    useAppStore.setState({
      progress: 3
    })
  }

  var currentStep = useAppStore(state => state.currentStep)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'CostBasisElection'
    })
  }

  return (
    <>
      <div className='flex justify-center'>
        <div className='w-full max-w-lg'>
          <MoneyInput
            name='investmentAmount'
            label='Enter Your Investment Amount'
            placeholder='$100,000'
          />
        </div>
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
