import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'FIFO',
    label: 'First-in, First-out (FIFO)',
    text: 'Employ the FIFO method for a systematic approach. This option sells the oldest shares first, providing a straightforward and chronological cost basis for your investments.'
  },
  {
    value: 'LIFO',
    label: 'Last-in, First-out (LIFO)',
    text: 'Opt for LIFO for a strategic perspective. This method sells the newest shares first, potentially deferring taxes as it considers the most recent costs.'
  },
  {
    value: 'HIFO',
    label: 'Highest Cost, First Out (HIFO)',
    text: 'Choose HIFO for a tax-efficient strategy. This method sells the shares with the highest cost first, potentially minimizing capital gains and optimizing your tax implications.'
  },
  {
    value: 'LOFO',
    label: 'Lowest Cost, First Out (LOFO)',
    text: 'Consider LOFO for a conservative approach. This method sells the shares with the lowest cost first, potentially maximizing capital gains and reducing tax liabilities.'
  }
]

export default function CostBasisElectionOptions({ step, methods }: any) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'Distribution'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='costBasisElection'
          label='Cost Basis Election'
          options={options}
        />
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
