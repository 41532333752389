'use client'

import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
//@ts-ignore
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { twMerge } from 'tailwind-merge'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
}

export const formatUTC = (dateInt: string, addOffset = false) => {
  let date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt)
  if (typeof dateInt === 'string') {
    return date
  } else {
    const offset = addOffset
      ? date.getTimezoneOffset()
      : -date.getTimezoneOffset()
    const offsetDate = new Date()
    offsetDate.setTime(date.getTime() + offset * 60000)
    return offsetDate
  }
}

export function DateField({ name, label, placeholder, type, ...rest }: Props) {
  const {
    setValue,
    control,
    watch,
    formState: { errors }
  } = useFormContext()

  const [date, setDate] = useState<Date>()

  const handleChange = (dateChange: string) => {
    setValue(name, formatUTC(dateChange), {
      shouldDirty: true
    })
    setDate(new Date(dateChange))
  }

  let showValue = watch(name)
  let formattedDate = new Date(showValue).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })

  return (
    <>
      <div className='relative my-4 pt-4'>
        <div className='relative *:w-full'>
          <Controller
            name={name}
            control={control}
            defaultValue={''}
            render={({ field: { value } }) => (
              <DatePicker
                selected={date}
                //value={value ? value : ''}
                placeholderText={
                  formattedDate === 'Invalid Date'
                    ? 'mm/dd/yyyy'
                    : formattedDate
                      ? formattedDate
                      : ''
                }
                dateFormat='MM dd yyyy'
                maxDate={new Date()}
                onChange={handleChange}
                className={twMerge(
                  `peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500  focus:outline-none focus:ring-0`,
                  formattedDate === 'Invalid Date'
                    ? 'border-navy/20 focus:border-navy'
                    : formattedDate
                      ? 'placeholder:text-navy focus:border-navy'
                      : 'placeholder-shown:border-navy/20 focus:border-navy'
                )}
              />
            )}
          />
        </div>
        <label
          htmlFor={name}
          className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
        >
          {label}
        </label>
        <svg
          width='19'
          height='21'
          viewBox='0 0 19 21'
          fill='none'
          className='pointer-events-none absolute bottom-2 right-3'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.8418 2.26562H15.8418V0.265625H13.8418V2.26562H5.8418V0.265625H3.8418V2.26562H2.8418C1.7318 2.26562 0.841797 3.16563 0.841797 4.26562V18.2656C0.841797 19.3656 1.7318 20.2656 2.8418 20.2656H16.8418C17.9418 20.2656 18.8418 19.3656 18.8418 18.2656V4.26562C18.8418 3.16563 17.9418 2.26562 16.8418 2.26562ZM16.8418 18.2656H2.8418V7.26562H16.8418V18.2656ZM4.3418 11.2656C4.3418 9.88562 5.4618 8.76562 6.8418 8.76562C8.2218 8.76562 9.3418 9.88562 9.3418 11.2656C9.3418 12.6456 8.2218 13.7656 6.8418 13.7656C5.4618 13.7656 4.3418 12.6456 4.3418 11.2656Z'
            fill='#49454F'
          />
        </svg>
      </div>
      <div>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className='mt-2 text-sm text-red'>{message}</p>
          )}
        />
      </div>
    </>
  )
}
