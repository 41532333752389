import postalCodes from 'postal-codes-js'

export const COUNTRIES_WITHOUT_POSTAL_CODE = [
  'AE',
  'AG',
  'AO',
  'AW',
  'BF',
  'BI',
  'BJ',
  'BO',
  'BS',
  'BW',
  'BZ',
  'CD',
  'CF',
  'CG',
  'CI',
  'CK',
  'CM',
  'DJ',
  'DM',
  'ER',
  'FJ',
  'GD',
  'GH',
  'GM',
  'GN',
  'GQ',
  'GY',
  'HK',
  'IE',
  'JM',
  'KE',
  'KI',
  'KM',
  'KN',
  'KP',
  'LC',
  'ML',
  'MO',
  'MR',
  'MS',
  'MU',
  'MW',
  'NR',
  'NU',
  'PA',
  'QA',
  'RW',
  'SA',
  'SB',
  'SC',
  'SL',
  'SO',
  'SR',
  'ST',
  'SY',
  'TF',
  'TK',
  'TL',
  'TO',
  'TT',
  'TV',
  'TZ',
  'UG',
  'VU',
  'YE',
  'ZA',
  'ZW'
]

const validatePostalCode = (countryCode: string) => (value: string) => {
  // Checking if the countryCode is in the list of countries with no postal code
  if (COUNTRIES_WITHOUT_POSTAL_CODE.includes(countryCode)) return true

  // Validating the postal code using postal-codes-js library
  const valid = postalCodes.validate(countryCode, value)

  // Returning undefined if the postal code is valid, otherwise returning an error message
  return valid === true ? true : false
}

export default validatePostalCode
