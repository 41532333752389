import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'averageCost',
    label: 'Average cost',
    text: 'Opt for simplicity with Average Cost. This method calculates the average price of all shares, offering a straightforward option for both you and the IRS.'
  },
  {
    value: 'otherOptions',
    label: 'Other Options',
    text: 'Choose flexibility with "Other Options." Tailor your cost basis calculation method to your strategy, be it FIFO, LIFO, or another, aligning reporting with your financial goals.'
  }
]

export default function CostBasisElection({ step, methods }: any) {
  useScrollToTop()
  var accountType = useAppStore(state => state.accountType)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 5
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 5
    })
  } else {
    useAppStore.setState({
      progress: 4
    })
  }

  var costBasisElection = methods.watch('costBasisElection')

  var currentStep = useAppStore(state => state.currentStep)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        costBasisElection === 'otherOptions'
          ? 'CostBasisElectionOptions'
          : 'Distribution'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='costBasisElection'
          label='Cost Basis Election'
          options={options}
        />
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
