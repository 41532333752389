import { Input } from '../Fields/Input'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import Pagination from '../Pagination'
import { useFormContext } from 'react-hook-form'
import { LocationSelect } from '../Fields/LocationSelect'
import useScrollToTop from '@/app/hooks/useScrollToTop'

interface Props {
  step: IStep
  methods: any
  countryData: any
  usStates: any
}

export default function EntityMailingInformation({
  step,
  methods,
  countryData,
  usStates
}: Props) {
  useScrollToTop()
  useAppStore.setState({
    progress: 8
  })
  const {
    register,
    control,
    reset,
    formState: { errors }
  } = useFormContext()
  var currentStep = useAppStore(state => state.currentStep)
  var intInvestor = useAppStore(state => state.intInvestor)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'EntityAuthorizedSignees'
    })
  }

  return (
    <>
      <input
        type='hidden'
        {...register('entityMailingInformationIntInvestor')}
        value={intInvestor ? 'true' : 'false'}
      />
      <div className='flex justify-center'>
        <div className='w-full max-w-lg'>
          <Input
            name='businessMailingAddressStreet'
            label='Business Mailing Address Street'
            placeholder='Enter Business Mailing Address Street'
          />
          <Input
            name='businessMailingAddressCity'
            label='Business Mailing Address City'
            placeholder='Enter Business Mailing Address City'
          />
          <div>
            <LocationSelect
              name='businessMailingAddress'
              label='Business Mailing Address'
              countryData={countryData}
              usStates={usStates}
            />
          </div>
          {intInvestor ? (
            <Input
              name='businessMailingAddressZip'
              label='Business Mailing Address Zip or Postal Code'
              placeholder='12345'
            />
          ) : (
            <Input
              name='businessMailingAddressZip'
              label='Business Mailing Address Zip'
              type='number'
              placeholder='12345'
            />
          )}
        </div>
      </div>
      <div className='mt-16 flex justify-center'>
        <Pagination
          prev={'EntityInformation'}
          next={next}
          conditions={step.conditions}
        />
      </div>
    </>
  )
}
