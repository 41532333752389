import useAppStore from '@/store/app-store'
import { Select } from '../Fields/Select'
import { RadioButtons } from '../Fields/RadioButtons'
import MoneyInput from '../Fields/MoneyInput'
import { Input } from '../Fields/Input'
import { useFormContext } from 'react-hook-form'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const accreditedInvestorOptions = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
]

export default function EarlyExitForeign() {
  useScrollToTop()
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext()
  useAppStore.setState({
    ineligible: true
  })
  var accountType = useAppStore(state => state.accountType)
  var iraState = useAppStore(state => state.iraState)
  var entityState = useAppStore(state => state.entityState)
  var accreditedInvestorState = useAppStore(
    state => state.accreditedInvestorState
  )
  const accreditedInvestor = watch('accreditedInvestor')
  if (accreditedInvestor === 'no') {
    useAppStore.setState({
      accreditedInvestorState: false
    })
  }
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 13
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 11
    })
  } else {
    useAppStore.setState({
      progress: 9
    })
  }
  return (
    <div className='mx-auto flex max-w-xl flex-col justify-center'>
      {entityState ? (
        <>
          <Input
            name='legalNameOfEntity'
            label='Legal Name of Entity'
            placeholder='Enter Legal Name of Entity'
          />
        </>
      ) : null}
      {iraState ? (
        <>
          <div>
            <Select
              name='iraType'
              label='IRA Type'
              placeholder='Select IRA Type'
            >
              <option value='Traditional IRA'>Traditional IRA</option>
              <option value='Roth IRA'>Roth IRA</option>
              <option value='SEP IRA'>SEP IRA</option>
            </Select>
          </div>
          <div>
            <Select
              name='custodianName'
              label='Custodian Name'
              placeholder='Select Custodian Name'
            >
              <option value='Goldstar Trust Company'>
                Goldstar Trust Company
              </option>
              <option value='NFS FMTC (Fidelity)'>NFS FMTC (Fidelity)</option>
              <option value='Charles Schwab &amp; Co Inc'>
                Charles Schwab &amp; Co Inc
              </option>
              <option value='Pershing LLC'>Pershing LLC</option>
              <option value='GSCS (Goldman Sachs)'>GSCS (Goldman Sachs)</option>
            </Select>
          </div>
        </>
      ) : null}

      <div>
        <MoneyInput
          name='investmentAmount'
          label='Enter Your Investment Amount'
          placeholder='$100,000'
        />
      </div>
      <div>
        <RadioButtons
          name='accreditedInvestor'
          label='Are you an Accredited Investor?'
          options={accreditedInvestorOptions}
        />
      </div>
      <div className='flex justify-center'>
        <button
          type='submit'
          disabled={isSubmitting}
          className='bg-navy px-6 py-4 text-sm font-bold uppercase tracking-wider text-white duration-500 hover:opacity-80'
        >
          {isSubmitting ? 'Submitting' : 'Submit'}
        </button>
      </div>
    </div>
  )
}
