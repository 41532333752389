'use client'

import { useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { CountryCode } from 'libphonenumber-js'
import useAppStore from '@/store/app-store'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
  value?: string
  countryData: any
}

export function IntPhoneNumberInput({
  name,
  label,
  placeholder,
  type,
  value,
  countryData,
  ...rest
}: Props) {
  const {
    register,
    unregister,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  var intInvestor = useAppStore(state => state.intInvestor)

  const countryList = countryData
    .map((country: { code: string }) => {
      return `${country.code}`
    })
    .sort()

  let showValue = watch(name)

  const [countryCode, setCountryCode] = useState<string>()

  useEffect(() => {
    register(name + 'CountryCode')
    setValue(name + 'CountryCode', countryCode)
  }, [countryCode])

  function onChange(value: string) {
    if (value) {
      setValue(name, value)
      setValue(name + 'CountryCode', countryCode)
    }
  }
  const countryListCodes: CountryCode[] = countryList.map(
    (code: string) => code as CountryCode
  )

  return (
    <div className='relative my-4 pt-4'>
      <PhoneInput
        onChange={onChange}
        countries={countryListCodes}
        onCountryChange={v => setCountryCode(v || '')}
        international
        countryCallingCodeEditable={false}
        className={`peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0`}
        id={name}
        placeholder={placeholder ? placeholder : ' '}
        value={showValue}
        numberInputProps={{
          className: 'border-0 focus:border-0',
          style: { border: '0', borderColor: 'transparent' }
        }}
      />
      <input className='hidden' {...register(name)} />
      <input className='hidden' {...register(name + 'CountryCode')} />
      <label
        htmlFor={name}
        className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
      >
        {label}
      </label>
    </div>
  )
}
