import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'yes',
    label: 'Yes',
    text: 'The trust has $5 million or more in assets and meets the legal standard of a Accredited Investor.'
  },
  {
    value: 'no',
    label: 'No',
    text: 'The trust does not have $5 million or more in assets'
  }
]

export default function TrustAccreditedInvestor({ step, methods }: any) {
  useScrollToTop()
  useAppStore.setState({
    progress: 3
  })

  var currentStep = useAppStore(state => state.currentStep)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return
    useAppStore.setState({
      currentStep: 'InvestmentAmount'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='accreditedInvestor'
          label='Trust Accredited Purchaser?'
          options={options}
        />
      </div>
      <Pagination
        prev={'TrustAccount'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
