import React from 'react'
import AccountType from './AccountType'
import BasicInfo from './BasicInfo'
import Review from './Review'
import Complete from './Complete'
import AccreditedInvestor from './AccreditedInvestor'
import JointAccount from './JointAccount'
import EntityAccount from './EntityAccount'
import InvestmentAmount from './InvestmentAmount'
import EarlyExit from './EarlyExit'
import EarlyExitComplete from './EarlyExitComplete'
import CostBasisElection from './CostBasisElection'
import CostBasisElectionOptions from './CostBasisElectionOptions'
import Distribution from './Distribution'
import FinancialInformation from './FinancialInformation'
import PersonalInformation from './PersonalInformation'
import CoOwnerInformation from './CoOwnerInformation'
import EntityInformation from './EntityInformation'
import EntityMailingInformation from './EntityMailingInformation'
import EntityAuthorizedSignees from './EntityAuthorizedSignees'
import EntityOwnerInformantion from './EntityOwnerInformantion'
import TrustAccount from './TrustAccount'
import TrustIrrevocableAccreditedInvestor from './TrustIrrevocableAccreditedInvestor'
import TrustRevocableAccreditedInvestor from './TrustRevocableAccreditedInvestor'
import DocumentUpload from './DocumentUpload'
import DocumentUploadTrust from './DocumentUploadTrust'
import DocumentUploadJoint from './DocumentUploadJoint'
import QualifiedPurchaser from './QualifiedPurchaser'
import JointAccreditedInvestor from './JointAccreditedInvestor'

const Components = {
  BasicInfo,
  EarlyExit,
  EarlyExitComplete,
  AccountType,
  QualifiedPurchaser,
  AccreditedInvestor,
  JointAccount,
  EntityAccount,
  InvestmentAmount,
  CostBasisElection,
  CostBasisElectionOptions,
  Distribution,
  FinancialInformation,
  PersonalInformation,
  CoOwnerInformation,
  EntityInformation,
  EntityMailingInformation,
  EntityAuthorizedSignees,
  EntityOwnerInformantion,
  TrustAccount,
  TrustIrrevocableAccreditedInvestor,
  TrustRevocableAccreditedInvestor,
  DocumentUpload,
  DocumentUploadTrust,
  DocumentUploadJoint,
  Review,
  Complete,
  JointAccreditedInvestor
}

interface Props {
  step: {
    id: string
    component: string
  }
  methods: any
  countryData: any
  usStates: any
}

export default function Steps({ step, methods, countryData, usStates }: Props) {
  // component does exist
  if (
    typeof Components[step.component as keyof typeof Components] !== 'undefined'
  ) {
    return React.createElement(
      Components[step.component as keyof typeof Components],
      {
        key: step.id,
        step,
        methods,
        //@ts-ignore
        countryData,
        //@ts-ignore
        usStates
      }
    )
  }

  // component doesn't exist yet
  return React.createElement(
    () => <div>The component {step.component} has not been created yet.</div>,
    { key: step?.id }
  )
}
