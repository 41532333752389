'use client'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
  children?: React.ReactNode
  usStates: any
  stateNameOverride?: string
}

export function UsStateSelect({
  name,
  label,
  usStates,
  stateNameOverride,
  ...rest
}: Props) {
  const {
    register,

    setValue,
    formState: { errors }
  } = useFormContext()

  // triggered when country is selected
  function onChange(value: string) {
    if (value) {
      setValue(stateNameOverride ? stateNameOverride : name + 'State', value)
    }
  }

  return (
    <div>
      <div className='relative my-4 pt-4'>
        <select
          {...register(stateNameOverride ? stateNameOverride : name + 'State')}
          {...rest}
          onChange={e => onChange(e.target.value)}
          className={`peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0`}
          id={name}
          placeholder={'Select State'}
          defaultValue={' '}
        >
          <option value='' disabled selected>
            Select State
          </option>
          {usStates.map((state: { name: string; code: string }) => (
            <option key={state.code} value={state.code}>
              {state.name}
            </option>
          ))}
        </select>
        <label
          htmlFor={name}
          className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
        >
          {label} State
        </label>

        <ErrorMessage
          errors={errors}
          name={name + 'Country'}
          render={({ message }) => (
            <p className='mt-2 text-sm text-red'>{message}</p>
          )}
        />
      </div>
    </div>
  )
}
