import react from 'react'
//@ts-ignore
import scriptloader from 'react-script-loader-hoc'

const vidyard_embed_js_url = 'https://play.vidyard.com/embed/v4.js'

declare global {
  interface Window {
    vidyardv4: any
  }
}

function VidyardPlayer(props: {
  scriptsloadedsuccessfully: boolean
  maxwidth: string
  maxheight: string
  type: string
  uuid: string
  aspect?: '4:3' | '16:9' | '16:10'
  onloadcallback?: (player: any, embedapi: any) => void
}) {
  const {
    scriptsloadedsuccessfully,
    maxwidth,
    maxheight,
    type,
    uuid,
    aspect,
    onloadcallback
  } = props
  const containerref = react.useRef<any>()

  react.useEffect(() => {
    if (scriptsloadedsuccessfully) {
      window.vidyardv4.api
        .renderplayer({
          aspect,
          container: containerref.current,
          height: maxheight,
          type,
          uuid,
          width: maxwidth
        })
        .then((player: any) => {
          if (onloadcallback) {
            onloadcallback(player, window.vidyardv4.api)
          }
        })
        .catch((e: any) => {
          // eslint-disable-next-line no-console
          console.error(e.message)
        })
    }
  }, [scriptsloadedsuccessfully])

  return <div ref={containerref} />
}

export default scriptloader(vidyard_embed_js_url)(VidyardPlayer)
