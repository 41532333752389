import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  name: string
  label: string
  options: {
    value: string
    label: string
  }[]
}

export function RadioButtons({ name, options, label, ...rest }: Props) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <div className='my-8'>
      <span className='mb-4 block text-center'>{label}</span>
      <fieldset id={name} className='flex items-center justify-center gap-2'>
        {options.map((option, index) => {
          return (
            <div key={index}>
              <label
                htmlFor={name + option.value}
                className='text-gray-900 block w-full border border-navy px-6 py-2 text-sm duration-200 hover:bg-navy/10 has-[:checked]:bg-navy has-[:checked]:text-white'
              >
                <input
                  {...register(name)}
                  {...rest}
                  type='radio'
                  className='hidden'
                  id={name + option.value}
                  value={option.value}
                />

                <span className='block font-bold uppercase'>
                  {option.label}
                </span>
              </label>
            </div>
          )
        })}
      </fieldset>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className='mt-2 text-sm text-red'>{message}</p>
        )}
      />
    </div>
  )
}
