'use client'

import Pagination from '../Pagination'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { Input } from '../Fields/Input'
import { DateField } from '../Fields/DateField'
import NumberFormatInput from '../Fields/NumberFormatInput'
import { PhoneNumberInput } from '../Fields/PhoneNumberInput'
import { IntPhoneNumberInput } from '../Fields/IntPhoneNumberInput'
import { useFormContext } from 'react-hook-form'
import useScrollToTop from '@/app/hooks/useScrollToTop'
import { CountrySelect } from '../Fields/CountrySelect'

interface Props {
  step: IStep
  methods: any
  countryData: any
}

export default function CoOwnerInformation({
  step,
  methods,
  countryData
}: Props) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)
  var intInvestor = useAppStore(state => state.intInvestor)

  const {
    register,
    formState: { errors }
  } = useFormContext()

  useAppStore.setState({
    progress: 9
  })

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep: 'DocumentUploadJoint'
    })
  }

  return (
    <>
      <div className='mx-auto grid max-w-6xl grid-cols-1 gap-x-6 md:grid-cols-2'>
        <input
          type='hidden'
          {...register('coOwnerInformationIntInvestor')}
          value={intInvestor ? 'true' : 'false'}
        />
        <div>
          <Input
            name='coOwnerFirstName'
            label='First Name'
            placeholder='First Name'
          />
        </div>
        <div>
          <Input
            name='coOwnerLastName'
            label='Last Name'
            placeholder='Last Name'
          />
        </div>
        <div>
          <Input
            name='coOwnerEmailAddress'
            label='Email Address'
            placeholder='Enter Email Address Here'
          />
        </div>
        {intInvestor ? (
          <div>
            <Input
              name='coOwnerSocialSecurity'
              label='Government ID Number'
              placeholder='XXXXXXXXXX'
            />
          </div>
        ) : (
          <div>
            <NumberFormatInput
              name='coOwnerSocialSecurity'
              label='Social Security'
              placeholder={'XXX-XX-XXXX'}
              format='###-##-####'
            />
          </div>
        )}

        <div>
          <DateField
            name='coOwnerBirthDate'
            label='Birth Date'
            placeholder='mm/dd/yyyy'
          />
        </div>
        <div>
          <PhoneNumberInput
            name='coOwnerPhoneNumber'
            label='Phone Number'
            countryData={countryData}
          />
        </div>
        {intInvestor ? (
          <div>
            <CountrySelect
              name='coOwnerCountryOfNationality'
              label='Country of Nationality'
              countryData={countryData}
            />
          </div>
        ) : null}
      </div>
      <div>
        <Pagination
          prev={'PersonalInformation'}
          next={next}
          conditions={step.conditions}
        />
      </div>
    </>
  )
}
