import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
  value?: string
  defaultValue?: string
}

export function Input({
  name,
  label,
  placeholder,
  type,
  value,
  ...rest
}: Props) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <div className='relative my-4 pt-4'>
      <input
        {...register(name)}
        {...rest}
        className='peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0'
        id={name}
        type={type ? type : 'text'}
        placeholder={placeholder ? placeholder : ' '}
        value={value ? value : undefined}
      />
      <label
        htmlFor={name}
        className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
      >
        {label}
      </label>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className='mt-2 text-sm text-red'>{message}</p>
        )}
      />
    </div>
  )
}
