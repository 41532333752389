'use client'
import { useFormContext } from 'react-hook-form'
import useAppStore from '@/store/app-store'
import { CountryAndStateSelect } from './CountryAndStateSelect'
import { UsStateSelect } from './UsStateSelect'

interface Props {
  name: string
  label: string
  value?: string
  countryData: any
  usStates: any
  countryNameOverride?: string
  stateNameOverride?: string
}

export function LocationSelect({
  name,
  label,
  value,
  countryData,
  usStates,
  countryNameOverride,
  stateNameOverride,
  ...rest
}: Props) {
  const {
    formState: { errors }
  } = useFormContext()

  var intInvestor = useAppStore(state => state.intInvestor)

  return (
    <div className='relative my-4'>
      {intInvestor ? (
        <CountryAndStateSelect
          name={name}
          label={label}
          countryData={countryData}
          countryNameOverride={countryNameOverride}
          stateNameOverride={stateNameOverride}
        />
      ) : (
        <UsStateSelect
          name={name}
          stateNameOverride={stateNameOverride}
          label={label}
          usStates={usStates}
        />
      )}
    </div>
  )
}
