import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'reinvestDistributions',
    label: 'Reinvest Distributions',
    text: 'Reinvesting the distributions will allow your investment to compound.'
  },
  {
    value: 'receiveViaCash',
    label: 'Receive Via Cash',
    text: 'If you prefer to receive your distributions directly, select this option.'
  }
]

export default function Distribution({ step, methods }: any) {
  useScrollToTop()
  var accountType = useAppStore(state => state.accountType)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 6
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 6
    })
  } else {
    useAppStore.setState({
      progress: 5
    })
  }

  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        accountType === 'trust' ? 'EntityInformation' : 'FinancialInformation'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='distribution'
          label='How are we handling the distribution?'
          options={options}
        />
      </div>
      <Pagination
        prev={'CostBasisElection'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
