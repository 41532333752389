interface Props {
  progress: number
  totalSteps: number
}

export default function Progress({ progress, totalSteps }: Props) {
  const total = totalSteps - 1
  var progressCalc = (progress * 100) / total
  var percent = progressCalc.toFixed(0) + '%'
  return (
    <div aria-label='Progress'>
      <div className='mb-2 h-1.5 w-full rounded-full bg-gold/10'>
        <div
          className='h-1.5 rounded-full bg-gold duration-200'
          style={{ width: percent }}
        ></div>
      </div>
      <span className='text-sm font-medium text-navy'>{percent} Complete</span>
    </div>
  )
}
