import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'jointTenantsInCommon',
    label: 'Joint Tenants in Common',
    text: 'Co-ownership with individual percentage interests that do not automatically transfer to the co-owner upon passing.'
  },
  {
    value: 'jointTenantsWithRightsOfSurvivorship',
    label: 'Joint Tenants with Rights of Survivorship',
    text: 'Co-ownership with an automatic transfer to the surviving co-owner upon passing.'
  },
  {
    value: 'tenantsByTheEntirety',
    label: 'Tenants by the Entirety',
    text: 'Married couples only. Co-ownership where the interests are automatically transferred to the surviving spouse.'
  }
]

export default function JointAccount({ step, methods }: any) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)

  useAppStore.setState({
    progress: 2,
    totalSteps: 13
  })

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    console.log(output)

    useAppStore.setState({
      currentStep: 'JointAccreditedInvestor'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='jointAccountType'
          label='Account Type'
          options={options}
        />
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
