import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
interface Props {
  name: string
  label: string
  placeholder?: string
}

export const MoneyInput = ({ name, label, placeholder, ...rest }: Props) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  const [rawValue, setRawValue] = useState<number>()

  let persistValue = watch(name)

  const handleValueChange = (newValue: string) => {
    const rawValueNumber = newValue === undefined ? 0 : parseFloat(newValue)
    setRawValue(rawValueNumber)
    validateValue(newValue)
  }

  const validateValue = (value: string | undefined): void => {
    const rawValue = value === undefined ? 0 : parseFloat(value)
    setRawValue(rawValue)

    setValue(name, rawValue)
  }

  return (
    <div className='relative my-8 pt-4'>
      <input {...register(name)} {...rest} className='hidden' type={'number'} />
      <CurrencyInput
        id={name}
        placeholder={placeholder ? placeholder : '$100,000'}
        allowDecimals={false}
        className={`peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0`}
        onValueChange={validateValue}
        prefix={'$'}
        step={10}
        value={persistValue ? persistValue : rawValue}
      />
      <label
        htmlFor={name}
        className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
      >
        {label}
      </label>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className='mt-2 text-sm text-red'>{message}</p>
        )}
      />
      <div className='mt-6 grid grid-cols-2 gap-4 md:grid-cols-4'>
        <div
          onClick={() => handleValueChange('50000')}
          className={twMerge(
            'cursor-pointer border border-navy px-4 py-2 text-center text-sm font-bold uppercase duration-200 hover:bg-navy/10 hover:text-navy',
            rawValue === 50000 ? 'bg-navy text-white' : ''
          )}
        >
          $50,000
        </div>
        <div
          onClick={() => handleValueChange('100000')}
          className={twMerge(
            'cursor-pointer border border-navy px-4 py-2 text-center text-sm font-bold uppercase duration-200 hover:bg-navy/10 hover:text-navy',
            rawValue === 100000 ? 'bg-navy text-white' : ''
          )}
        >
          $100,000
        </div>
        <div
          onClick={() => handleValueChange('125000')}
          className={twMerge(
            'cursor-pointer border border-navy px-4 py-2 text-center text-sm font-bold uppercase duration-200 hover:bg-navy/10 hover:text-navy',
            rawValue === 125000 ? 'bg-navy text-white' : ''
          )}
        >
          $125,000
        </div>
        <div
          onClick={() => handleValueChange('200000')}
          className={twMerge(
            'cursor-pointer border border-navy px-4 py-2 text-center text-sm font-bold uppercase duration-200 hover:bg-navy/10 hover:text-navy',
            rawValue === 200000 ? 'bg-navy text-white' : ''
          )}
        >
          $200,000
        </div>
      </div>
      <div className='mt-6'>
        <ul className='text-center'>
          <li className='flex items-center justify-center gap-1'>
            <span className='flex h-4 w-4 items-center justify-center rounded-full bg-gold text-xs text-white'>
              <svg
                width='3'
                height='8'
                viewBox='0 0 3 8'
                fill='none'
                className='fill-current'
              >
                <path d='M0.939473 0.15332H2.34172L2.21615 5.30188H1.05458L0.939473 0.15332ZM0.792969 6.7146C0.792969 6.22277 1.1383 5.92976 1.6406 5.92976C2.12197 5.92976 2.47776 6.22277 2.47776 6.7146C2.47776 7.19597 2.12197 7.48898 1.6406 7.48898C1.1383 7.48898 0.792969 7.19597 0.792969 6.7146Z' />
              </svg>
            </span>
            Increments: $1,000
          </li>
          <li className='flex items-center justify-center gap-1'>
            <span className='flex h-4 w-4 items-center justify-center rounded-full bg-gold text-xs text-white'>
              <svg
                width='3'
                height='8'
                viewBox='0 0 3 8'
                fill='none'
                className='fill-current'
              >
                <path d='M0.939473 0.15332H2.34172L2.21615 5.30188H1.05458L0.939473 0.15332ZM0.792969 6.7146C0.792969 6.22277 1.1383 5.92976 1.6406 5.92976C2.12197 5.92976 2.47776 6.22277 2.47776 6.7146C2.47776 7.19597 2.12197 7.48898 1.6406 7.48898C1.1383 7.48898 0.792969 7.19597 0.792969 6.7146Z' />
              </svg>
            </span>
            Investment Range: $25,000-$500,000+ (Min. $25,000, No Maximum)
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MoneyInput
