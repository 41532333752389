import useAppStore from '@/store/app-store'
import RadioGroup from '../Fields/RadioGroup'
import Pagination from '../Pagination'
import useScrollToTop from '@/app/hooks/useScrollToTop'

const options = [
  {
    value: 'revocable',
    label: 'Revocable',
    text: 'Often called a “Living Trust”',
    tooltip: {
      title: 'Revocable',
      text: 'A revocable trust is a trust whereby provisions can be altered or canceled depending on the wishes of the grantor or the originator of the trust. During the life of the trust, income earned is distributed to the grantor, and only after death does property transfer to the beneficiaries of the trust.'
    }
  },
  {
    value: 'irrevocable',
    label: 'Irrevocable',
    text: 'Often called a “Grantor Trust”',
    tooltip: {
      title: 'Irrevocable',
      text: 'An irrevocable trust refers to any trust where the grantor cannot change or end the trust after its creation. Grantors may choose a trust with such limitations to limit estate taxes or to shield assets from creditors.'
    }
  }
]

export default function TrustAccount({ step, methods }: any) {
  useScrollToTop()
  useAppStore.setState({
    progress: 2,
    totalSteps: 15
  })

  var currentStep = useAppStore(state => state.currentStep)

  var trustAccountType = methods.watch('trustAccountType')

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        trustAccountType == 'revocable'
          ? 'TrustRevocableAccreditedInvestor'
          : 'TrustIrrevocableAccreditedInvestor'
    })
  }

  return (
    <>
      <div className='mx-auto w-full max-w-lg'>
        <RadioGroup
          name='trustAccountType'
          label='Account Type'
          options={options}
        />
      </div>
      <Pagination
        prev={'AccountType'}
        next={next}
        conditions={step.conditions}
      />
    </>
  )
}
