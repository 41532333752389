import useScrollToTop from '@/app/hooks/useScrollToTop'
import useAppStore from '@/store/app-store'
import { useFormContext } from 'react-hook-form'

export default function Review({ step, methods }: any) {
  useScrollToTop()
  useAppStore.setState({
    ineligible: false
  })
  var accountType = useAppStore(state => state.accountType)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 13
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 11
    })
  } else {
    useAppStore.setState({
      progress: 9
    })
  }
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext()
  const data = watch()

  const formattedInvestmentAmount = new Intl.NumberFormat('en-US').format(
    data.investmentAmount
  )

  return (
    <div className='mx-auto max-w-4xl'>
      <div className='mx-auto mb-16 grid max-w-6xl grid-cols-1 gap-4 *:border *:border-gray *:p-6 md:grid-cols-2'>
        <div className='flex flex-col gap-2'>
          <p className='text-navy/50'>Authorized Investor</p>
          <p className='text-2xl'>
            {data.firstName} {data.lastName}
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <p className='text-navy/50'>Your Investment Amount</p>
          <p className='text-2xl'>${formattedInvestmentAmount}</p>
        </div>
      </div>
      <div className='my-16 flex justify-between gap-4'>
        <button
          type='button'
          onClick={() => {
            useAppStore.setState({
              currentStep:
                accountType == 'trust'
                  ? 'DocumentUploadTrust'
                  : accountType == 'joint'
                    ? 'DocumentUploadJoint'
                    : 'DocumentUpload'
            })
          }}
          className='border border-navy bg-white px-8 py-3 text-sm font-semibold uppercase tracking-wide text-navy shadow-sm duration-200 hover:bg-navy/10'
        >
          Previous
        </button>
        <button
          type='submit'
          disabled={isSubmitting}
          className='bg-navy px-6 py-4 text-sm font-bold uppercase tracking-wider text-white duration-500 hover:opacity-80'
        >
          {isSubmitting ? 'Submitting' : 'Confirm Investment'}
        </button>
      </div>
    </div>
  )
}
