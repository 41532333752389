'use client'

import { motion } from 'framer-motion'
import Progress from '@/components/Progress'

import { z } from 'zod'
import { FormDataSchema } from '@/lib/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useSearchParams } from 'next/navigation'

import Steps from '@/components/Steps'
import Form from './Fields/Form'

import useAppStore from '@/store/app-store'
import { Suspense } from 'react'

type Inputs = z.infer<typeof FormDataSchema>

const COMPLETE = 'Complete'
const INELIGIBLE_COMPLETE = 'IneligibleComplete'
const NOT_ACCREDITED_COMPLETE = 'NotAccreditedComplete'
const formFinalSteps = [COMPLETE, INELIGIBLE_COMPLETE, NOT_ACCREDITED_COMPLETE]

const steps = [
  {
    id: 'BasicInfo',
    title: 'Let’s get the basics covered.',
    text: 'Give us your legal name and information to lock in your account.',
    component: 'BasicInfo',
    conditions: [
      'firstName',
      'lastName',
      'emailAddress',
      'usTaxable',
      'riaRep',
      'phoneNumber'
    ]
  },
  {
    id: 'UsTaxableExit',
    title: 'Foreign Investors - Investment Process',
    text: 'Investors outside the US are required to speak with a member of our team. A CAZ team member will be reaching out shortly. Thank you.',
    component: 'EarlyExit',
    conditions: []
  },
  {
    id: 'RiaExit',
    title: 'Thank you for your interest!',
    text: 'We very much look forward to working with you and your investor base! We will have somebody from our team reach out to you shortly in order to discus next steps.',
    component: 'EarlyExit',
    conditions: []
  },
  {
    id: 'AccountType',
    title: 'How do you plan on investing with us?',
    text: 'Select an account type to get started.',
    component: 'AccountType',
    conditions: ['accountType']
  },
  {
    id: 'JointAccount',
    title: 'What type of joint account do you have?',
    text: 'Select an account type to get started.',
    component: 'JointAccount',
    conditions: ['jointAccountType']
  },
  {
    id: 'EntityAccount',
    title: 'What type of entity account do you have?',
    text: 'Select an account type to get started.',
    component: 'EntityAccount',
    conditions: ['entityAccount']
  },
  {
    id: 'QualifiedPurchaser',
    title: 'Are you a Qualified Purchaser, an Accredited Investor or Neither',
    text: '',
    component: 'QualifiedPurchaser',
    conditions: ['qualifiedPurchaser']
  },
  {
    id: 'AccreditedInvestor',
    title: 'Are you an Accredited Investor?',
    text: 'In order to invest in the CAZ Strategic Opportunities Fund, you must attest here that you are indeed an “Accredited Investor” with EITHER annual income of greater than $200,000 ($300,000 if filing jointly) OR greater than $1 million net worth (excluding value of primary residence).',
    component: 'AccreditedInvestor',
    conditions: ['accreditedInvestor']
  },
  {
    id: 'JointAccreditedInvestor',
    title: 'Are you an Accredited Investor?',
    text: 'In order to invest in the CAZ Strategic Opportunities Fund, you must attest here that you are indeed an “Accredited Investor” with EITHER annual income of greater than $200,000 ($300,000 if filing jointly) OR greater than $1 million net worth (excluding value of primary residence).',
    component: 'JointAccreditedInvestor',
    conditions: ['accreditedInvestor']
  },
  {
    id: 'InvestmentAmount',
    title: 'How much do you want to invest?',
    text: 'The fund requires a minimum investment of $25,000 with no limitation the total size of the investment.',
    component: 'InvestmentAmount',
    conditions: ['investmentAmount']
  },
  {
    id: 'CostBasisElection',
    title: 'Cost Basis Election',
    text: 'In order to provide you and the IRS with accurate cost basis information for your covered Shares, please elect one of the methods below. If you do not select a method, the account(s) will default to average cost.',
    component: 'CostBasisElection',
    conditions: ['costBasisElection']
  },
  {
    id: 'CostBasisElectionOptions',
    title: 'Cost Basis Election',
    text: 'In order to provide you and the IRS with accurate cost basis information for your covered Shares, please elect one of the methods below. If you do not select a method, the account(s) will default to average cost.',
    component: 'CostBasisElectionOptions',
    conditions: ['costBasisElection']
  },
  {
    id: 'Distribution',
    title: 'How are we handling the distribution?',
    text: 'The fund will likely generate periodic distributions to the investors.  Let us know if you would like to reinvest or have them sent to you.',
    component: 'Distribution',
    conditions: ['distribution']
  },
  {
    id: 'FinancialInformation',
    title: 'Financial Information',
    text: 'Please provide your banking instructions in order to receive repurchase proceeds, dividends, or short and long-term capital gain cash distributions (if applicable).',
    component: 'FinancialInformation',
    conditions: [
      'bankName',
      'bankStreet',
      'bankCity',
      'bankCountry',
      'bankState',
      'bankZip',
      'bankAccountType',
      'abaNumber',
      'bankIdentifierCode',
      'bankAccountNumber',
      'nameOnBankAccount'
    ]
  },
  {
    id: 'PersonalInformation',
    title: 'Personal Information',
    text: 'The collection of personal information is fundamental to ensuring a secure, compliant, and personalized investment experience for individuals.',
    component: 'PersonalInformation',
    conditions: [
      'mailingAddressNumberAndStreet',
      'mailingAddressCity',
      'mailingAddressCountry',
      'mailingAddressState',
      'mailingAddressZip',
      'socialSecurity',
      'birthDate',
      'intInvestor',
      'countryOfNationality'
    ]
  },
  {
    id: 'CoOwnerInformation',
    title: 'Co-Owner Information ',
    text: 'Please provide the information for the joint owner of this account.',
    component: 'CoOwnerInformation',
    conditions: [
      'coOwnerInformationIntInvestor',
      'coOwnerFirstName',
      'coOwnerLastName',
      'coOwnerEmailAddress',
      'coOwnerSocialSecurity',
      'coOwnerBirthDate',
      'coOwnerPhoneNumber',
      'coOwnerCountryOfNationality'
    ]
  },
  {
    id: 'EntityExit',
    title: 'Your investment may qualify for different share classes.',
    text: 'A CAZ Representative will be reaching out shortly to schedule a call. We look forward to connecting.',
    component: 'EarlyExit',
    conditions: []
  },
  {
    id: 'EarlyExit',
    title: 'A few more details.',
    text: 'In order to get the process started, let’s gather a few details regarding your investment.',
    component: 'EarlyExit',
    conditions: []
  },
  {
    id: 'EntityInformation',
    title: 'Entity Information',
    text: 'Please provide additional details for the legal entity you will be investing with as outlined below',
    component: 'EntityInformation',
    conditions: [
      'entityInformationIntInvestor',
      'legalNameOfEntity',
      'dateOfOrganization',
      'countryOfFormation',
      'taxIdentificationNumber',
      'businessAddressStreet',
      'businessAddressCity',
      'businessAddressCountry',
      'businessAddressState',
      'businessAddressZip',
      'entityPrimaryPhoneNumber',
      'doesBusinessAddressDifferFromMailingAddress'
    ]
  },
  {
    id: 'EntityMailingInformation',
    title: 'Entity Mailing Information',
    component: 'EntityMailingInformation',
    conditions: [
      'businessMailingAddressStreet',
      'businessMailingAddressCity',
      'businessAddressMailingCountry',
      'businessMailingAddressState',
      'businessMailingAddressZip'
    ]
  },
  {
    id: 'EntityAuthorizedSignees',
    title: 'Trustee(s), Partners or other Authorized Signers',
    text: 'Please list the Entity Authorized Signees, including yourself in applicable. (Up to 2 Max)',
    component: 'EntityAuthorizedSignees',
    conditions: ['entityAuthorizedSignees']
  },
  {
    id: 'EntityOwnerInformantion',
    title: 'Owner Information',
    text: 'Please list only the owners with 25% or more ownership. (Up to 4 Max)',
    component: 'EntityOwnerInformantion',
    conditions: ['entityOwnerInformantion']
  },
  {
    id: 'TrustAccount',
    title: 'Tell us the type of trust?',
    text: 'Please indicate if you would like to invest through a Revocable Trust (aka a living trust) or an Irrevocable Trust (aka a grantor trust).',
    component: 'TrustAccount',
    conditions: ['trustAccountType']
  },
  {
    id: 'TrustIrrevocableAccreditedInvestor',
    title: 'Is the Trust an Accredited Investor?',
    text: 'An irrevocable trust must have $5 million or more in assets',
    component: 'TrustIrrevocableAccreditedInvestor',
    conditions: ['trustIrrevocableAccreditedInvestor']
  },
  {
    id: 'TrustRevocableAccreditedInvestor',
    title: 'Are you an Accredited Investor?',
    text: 'In order to invest in the CAZ Strategic Opportunities Fund, you must attest here that you are indeed an “Accredited Investor” with EITHER annual income of greater than $200k ($300k if filing jointly) OR greater than $1M net worth (excluding value of primary residence).',
    component: 'TrustRevocableAccreditedInvestor',
    conditions: ['trustRevocableAccreditedInvestor']
  },
  {
    id: 'DocumentUpload',
    title: 'Upload Your Documents',
    text: 'Uploading documents is a crucial step in the investment process as it allows us to verify and authenticate your identity, financial information, and compliance with regulatory requirements.',
    component: 'DocumentUpload',
    conditions: ['documents']
  },
  {
    id: 'DocumentUploadTrust',
    title: 'Upload Your Documents',
    text: 'Uploading documents is a crucial step in the investment process as it allows us to verify and authenticate your identity, financial information, and compliance with regulatory requirements.',
    component: 'DocumentUploadTrust',
    conditions: ['trustFormationDocument', 'OwnerDocuments']
  },
  {
    id: 'DocumentUploadJoint',
    title: 'Upload Your Documents',
    text: 'Uploading documents is a crucial step in the investment process as it allows us to verify and authenticate your identity, financial information, and compliance with regulatory requirements.',
    component: 'DocumentUploadJoint',
    conditions: ['ownerOneDocuments', 'ownerTwoDocuments']
  },
  {
    id: 'Review',
    title: 'Confirm Your Investment',
    text: 'Confirm your investment below and you will be receiving a follow up email for e-signature and wire instructions',
    component: 'Review',
    conditions: []
  },
  {
    id: COMPLETE,
    title: 'Next Steps',
    component: 'Complete',
    conditions: []
  },
  {
    id: INELIGIBLE_COMPLETE,
    title: 'Next Steps',
    text: 'Thank you for providing the details for your investment in our CAZ Strategic Opportunities Fund. Please look for an email from +SUBSCRIBE in the next 2 business days regarding next steps. If you have any challenges or do not receive the email in 2 business days, please contact us at MyTeam@cazinvestments.com.',
    component: 'EarlyExitComplete',
    conditions: []
  },
  {
    id: NOT_ACCREDITED_COMPLETE,
    title: 'Thank you!',
    text: 'The CAZ Strategic Opportunity Fund is available only Accredited Investors. However, we’ll continue to provide valuable content through The Holy Grail of Investing platform, including a forthcoming podcast and possibly a newsletter to educate investors. We’ll keep you informed about any changes, such as potential legislation enabling you to become an Accredited Investor. If you need financial planning or investment advice, we can refer you to trusted firms. Please let us know if you’d like assistance with that.',
    component: 'EarlyExitComplete',
    conditions: []
  }
]

export default function WizardForm({
  countryData,
  usStates
}: {
  countryData: any
  usStates: any
}) {
  const methods = useForm<Inputs>({
    resolver: zodResolver(FormDataSchema)
  })
  var intInvestor = useAppStore(state => state.intInvestor)
  const watchAllFields = methods.watch()

  var ineligible = useAppStore(state => state.ineligible)
  var accreditedInvestorState = useAppStore(
    state => state.accreditedInvestorState
  )

  const searchParams = useSearchParams()
  const sofFormResponse = searchParams.get('sof_form_response') as string
  const auth0UserId = searchParams.get('user_id') as string

  if (formFinalSteps.includes(sofFormResponse)) {
    useAppStore.setState({
      currentStep: sofFormResponse
    })
  }

  async function updateAuth0UserMetadata(data: any) {
    const res = await fetch('api/updateAuth0User', {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  async function processForm(data: any) {
    //console.log('Submitted')
    if (ineligible) {
      try {
        const res = await fetch('api/palantir', {
          method: 'POST',
          body: JSON.stringify(data)
        })
        // console.log(res)
        if (res.ok) {
          console.log('Submission: ' + res.statusText)
          const nextStep = accreditedInvestorState
            ? INELIGIBLE_COMPLETE
            : NOT_ACCREDITED_COMPLETE
          useAppStore.setState({
            currentStep: nextStep
          })
          updateAuth0UserMetadata({
            user_id: auth0UserId,
            data: {
              user_metadata: {
                sof_form_response: nextStep
              }
            }
          })
        } else {
          console.log('Submission: ' + res.statusText)
        }
      } catch (error) {
        console.log(error)
      }
    } else if (intInvestor == true) {
      //console.log('International Investor')
      try {
        const res = await fetch('/api/intSubmitForm', {
          method: 'POST',
          body: JSON.stringify(data)
        })
        // console.log(res)
        if (res.ok) {
          console.log('Submission: ' + res.statusText)
          useAppStore.setState({
            currentStep: COMPLETE
          })
          updateAuth0UserMetadata({
            user_id: auth0UserId,
            data: {
              user_metadata: {
                sof_form_response: COMPLETE
              }
            }
          })
        } else {
          console.log('Submission: ' + res.statusText)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      //console.log('US Investor')
      try {
        const res = await fetch('/api/submitForm', {
          method: 'POST',
          body: JSON.stringify(data)
        })
        // console.log(res)
        if (res.ok) {
          console.log('Submission: ' + res.statusText)
          useAppStore.setState({
            currentStep: COMPLETE
          })
          updateAuth0UserMetadata({
            user_id: auth0UserId,
            data: {
              user_metadata: {
                sof_form_response: COMPLETE
              }
            }
          })
        } else {
          console.log('Submission: ' + res.statusText)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  var currentStep = useAppStore(state => state.currentStep)
  var progress = useAppStore(state => state.progress)
  var totalSteps = useAppStore(state => state.totalSteps)

  const showData = false

  return (
    <section className='mx-auto flex w-full max-w-6xl flex-col font-light text-navy'>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(processForm)}>
          {/* Progress indicator  */}
          <Progress progress={progress} totalSteps={totalSteps} />

          {/* Form */}
          <Suspense>
            {steps.map((step, index) => (
              <div key={step.id}>
                {step.id === currentStep && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                  >
                    <div className=' mx-auto my-8 flex flex-col items-center text-center md:my-16'>
                      <h2 className='mb-4 max-w-5xl  font-serif text-3xl tracking-tighter md:text-5xl lg:text-7xl'>
                        {step.title}
                      </h2>
                      <p className='max-w-3xl text-lg '>{step.text}</p>
                    </div>
                    <Steps
                      step={step}
                      methods={methods}
                      countryData={countryData}
                      usStates={usStates}
                    />
                  </motion.div>
                )}
              </div>
            ))}
          </Suspense>
        </Form>
      </FormProvider>

      {showData ? (
        <>
          <pre className='mt-16 rounded bg-navy/10 p-6 text-xs'>
            International: {JSON.stringify(intInvestor, null, 2)}
            <br />
            {JSON.stringify(watchAllFields, null, 2)}
          </pre>
        </>
      ) : null}
    </section>
  )
}
