import useAppStore from '@/store/app-store'

import React, { useRef } from 'react'
import VidyardPlayer from '../VidyardPlayer'
import useScrollToTop from '@/app/hooks/useScrollToTop'

export default function Complete() {
  useScrollToTop()
  var accountType = useAppStore(state => state.accountType)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 14
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 12
    })
  } else {
    useAppStore.setState({
      progress: 10
    })
  }

  const container = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={container}>
        <div className='mx-auto mb-8 max-w-lg'>
          <VidyardPlayer />
          <img
            style={{ width: '100%', margin: 'auto', display: 'block' }}
            className='vidyard-player-embed '
            src='https://play.vidyard.com/gyA6fT27fenj48sLXqNxJ9.jpg'
            data-uuid='gyA6fT27fenj48sLXqNxJ9'
            data-v='4'
            data-type='lightbox'
          />
        </div>
      </div>
      <div className='mx-auto grid max-w-6xl grid-cols-1 gap-4 *:bg-gray/25 *:p-6 md:grid-cols-3'>
        <div className='flex flex-col justify-between gap-8'>
          <p className='font-serif text-7xl'>1</p>
          <div>
            <p className='text-lg font-bold'>Signature</p>
            <p className='text-lg'>
              You will be emailed a Docusign for your electronic signature on
              the fund subscription document.
            </p>
          </div>
        </div>
        <div className='flex flex-col justify-between gap-8'>
          <p className='font-serif text-7xl'>2</p>
          <div>
            <p className='text-lg font-bold'>Wire or ACH Transfer</p>
            <p className='text-lg'>
              Upon completion of the Docusign, you will receive an email with a
              secure link with transfer instructions.
            </p>
          </div>
        </div>
        <div className='flex flex-col justify-between gap-8'>
          <p className='font-serif text-7xl'>3</p>
          <div>
            <p className='text-lg font-bold'>Investor Log-In</p>
            <p className='text-lg'>
              Upon receipt of funds, our fund administrator will be mailing you
              additional information that will allow you to set up access to the
              investor portal.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
