import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  name: string
  label: string
  options: {
    value: string
    label: string
  }[]
}

export function Radio({ name, options, label, ...rest }: Props) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <fieldset>
        <legend className='mb-4 block'>{label}</legend>
        <div className='flex items-center gap-4'>
          {options.map((option, index) => {
            return (
              <div key={index} className='flex items-center gap-2'>
                <input
                  {...register(name)}
                  {...rest}
                  type='radio'
                  className=''
                  id={option.value}
                  value={option.value}
                />
                <label htmlFor={option.value} className=''>
                  {option.label}
                </label>
              </div>
            )
          })}
        </div>
      </fieldset>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className='mt-2 text-sm text-red'>{message}</p>
        )}
      />
    </>
  )
}
