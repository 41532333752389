'use client'

import Pagination from '../Pagination'
import useAppStore from '@/store/app-store'
import { IStep } from '@/types'
import { Input } from '../Fields/Input'
import NumberFormatInput from '../Fields/NumberFormatInput'
import { Select } from '../Fields/Select'
import { LocationSelect } from '../Fields/LocationSelect'
import { useFormContext } from 'react-hook-form'
import useScrollToTop from '@/app/hooks/useScrollToTop'
interface Props {
  step: IStep
  methods: any
  countryData: any
  usStates: any
}

export default function FinancialInformation({
  step,
  methods,
  countryData,
  usStates
}: Props) {
  useScrollToTop()
  var currentStep = useAppStore(state => state.currentStep)
  var accountType = useAppStore(state => state.accountType)
  var intInvestor = useAppStore(state => state.intInvestor)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 11
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 7
    })
  } else {
    useAppStore.setState({
      progress: 6
    })
  }

  const { register } = useFormContext()

  const next = async () => {
    const conditions = step.conditions
    const output = await methods.trigger(conditions, {
      shouldFocus: true
    })

    if (!output) return

    useAppStore.setState({
      currentStep:
        accountType === 'trust' ? 'DocumentUploadTrust' : 'PersonalInformation'
    })
  }

  return (
    <>
      <input
        type='hidden'
        {...register('financialInformationIntInvestor')}
        value={intInvestor ? 'true' : 'false'}
      />
      <div className='mx-auto grid max-w-6xl grid-cols-1 gap-x-6 md:grid-cols-2'>
        <div>
          <Input
            name='bankName'
            label='Bank Name'
            placeholder='Enter Full Bank Name'
          />
        </div>
        <div>
          <Input
            name='bankStreet'
            label='Bank Street'
            placeholder='Enter Street Name'
          />
        </div>
        <div>
          <Input name='bankCity' label='Bank City' placeholder='Bank City' />
        </div>
        <div>
          <LocationSelect
            name='bank'
            label='Bank'
            countryData={countryData}
            usStates={usStates}
          />
        </div>
        <div>
          {intInvestor ? (
            <Input
              name='bankZip'
              label='Bank Zip or Postal Code'
              placeholder='12345'
            />
          ) : (
            <Input
              name='bankZip'
              label='Bank Zip'
              type='number'
              placeholder='12345'
            />
          )}
        </div>
        <div>
          <Select
            name='bankAccountType'
            label='Account Type'
            placeholder='Select Account Type'
          >
            <option value='checking'>Checking</option>
            <option value='savings'>Savings</option>
            <option value='brokerage'>Brokerage</option>
          </Select>
        </div>
        <div>
          {intInvestor ? (
            <Input
              name='bankIdentifierCode'
              label='Swift/BIC Code'
              placeholder='#############'
            />
          ) : (
            <NumberFormatInput
              name='abaNumber'
              label='ABA Number'
              placeholder={'0000-000-00'}
              format='####-###-##'
            />
          )}
        </div>
        <div>
          <Input
            name='nameOnBankAccount'
            label='Name on Account'
            placeholder='Enter Name on Account'
          />
        </div>
        <div>
          <Input
            name='bankAccountNumber'
            label='Account Number'
            placeholder='000000000000'
          />
        </div>
        <div>
          <Input
            name='forFurtherCreditName'
            label='For Further Credit Name (if applicable)'
            placeholder='Enter Credit Name'
          />
        </div>
        <div>
          <Input
            name='forFurtherCreditNumber'
            label='For Further Credit Number (if applicable)'
            placeholder='Enter Credit Number'
          />
        </div>
        <div>
          <Input
            name='referenceNumber'
            label='For Further Credit Reference (if applicable)'
            placeholder='Enter Reference Number'
          />
        </div>
      </div>
      <div>
        <Pagination
          prev={
            accountType === 'trust' ? 'EntityOwnerInformantion' : 'Distribution'
          }
          next={next}
          conditions={step.conditions}
        />
      </div>
    </>
  )
}
