'use client'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useCallback, useState } from 'react'
import { getStates } from '@/lib/countryData'

interface Props {
  name: string
  label: string
  type?: string
  placeholder?: string
  children?: React.ReactNode
  countryData: any
  stateNameOverride?: string
  countryNameOverride?: string
}

export function CountryAndStateSelect({
  name,
  label,
  placeholder,
  type,
  children,
  countryData,
  stateNameOverride,
  countryNameOverride,
  ...rest
}: Props) {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext()

  const [stateLoading, setStateLoading] = useState(true)
  const [states, setStates] = useState([])

  const countryName = countryNameOverride
    ? countryNameOverride
    : name + 'Country'
  const stateName = stateNameOverride ? stateNameOverride : name + 'State'

  //fetch the list of states filtered by country code
  const fetchStates = useCallback(async (countryCode: string) => {
    const req = await getStates(countryCode)
    const data = await req
    if (data) {
      setStateLoading(false)
    }
    setStates(data)
  }, [])

  // triggered when country is selected
  function onChange(value: string) {
    if (value) {
      fetchStates(value)
      setStateLoading(true)

      setValue(stateName, '')
    }
  }

  return (
    <div>
      <div className='relative my-4 pt-4'>
        <select
          {...register(countryName)}
          {...rest}
          onChange={e => onChange(e.target.value)}
          className={`peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0`}
          id={stateNameOverride ? stateNameOverride : name + 'Country'}
          placeholder={'Select Country'}
          defaultValue={''}
        >
          <option value='' disabled selected>
            Select Country
          </option>
          {countryData.map((country: { name: string; code: string }) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
        <label
          htmlFor={name}
          className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
        >
          {label} Country
        </label>

        <ErrorMessage
          errors={errors}
          name={countryName}
          render={({ message }) => (
            <p className='mt-2 text-sm text-red'>{message}</p>
          )}
        />
      </div>
      {states.length > 0 ? (
        <div className='relative my-4 pt-4'>
          <select
            {...register(stateName)}
            {...rest}
            //onChange={evalue => onChange()}
            className={`peer relative block w-full border-0 border-b-2 border-navy px-2 duration-500 placeholder:text-navy/50 placeholder-shown:border-navy/20 focus:border-navy focus:outline-none focus:ring-0 ${
              stateLoading ? 'opacity-20' : ''
            }`}
            id={stateName}
            placeholder={'Select State'}
            defaultValue={''}
            disabled={stateLoading}
          >
            <option value='' disabled selected>
              Select State
            </option>
            {states.map((state: { name: string; code: string }) => (
              <option key={state.code} value={state.code}>
                {state.name}
              </option>
            ))}
          </select>
          <label
            htmlFor={name}
            className='absolute top-0 text-sm text-navy/50 transition-all duration-200 peer-placeholder-shown:text-navy peer-focus:text-navy'
          >
            {label} State
          </label>

          <ErrorMessage
            errors={errors}
            name={stateNameOverride ? stateNameOverride : name + 'State'}
            render={({ message }) => (
              <p className='mt-2 text-sm text-red'>{message}</p>
            )}
          />
        </div>
      ) : null}
    </div>
  )
}
